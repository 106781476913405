import styled, { css } from 'styled-components';
import { lighten } from 'Components/Utils';

import { Typography } from '../Typography';

export const SelectWrapper = styled.div`
  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
  ${props =>
    props.block &&
    css`
      width: 100%;
    `}

  .select-dropdown {
    width: 100%;
  }

  .dropdown-input {
    background: ${props => props.theme.palette.colors.white};
  }
  ${props =>
    props.hasList &&
    css`
      .dropdown-input {
        padding: ${props => props.theme.dsSpacing(1)};
      }
    `}
`;

export const DropdownWrapper = styled.div`
  max-height: 250px;
  overflow: auto;
  background: ${props => props.theme.palette.colors.white};
  box-shadow: ${props => props.theme.config.shadows[3]};
`;

export const Options = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.dsSpacing(2)};
  border-bottom: 1px solid ${props => props.theme.palette.borderColors.default};
  cursor: pointer;

  &:hover {
    background: ${props =>
      lighten(props.theme.palette.colors[props.activecolor], 0.9)};
  }

  ${props =>
    props.isSelected &&
    css`
      background: ${props =>
        lighten(props.theme.palette.colors[props.activecolor], 0.9)};
    `}
`;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Tag = styled.span`
  display: flex;
  margin: ${props => props.theme.dsSpacing(0.5)};
  padding: ${props => props.theme.dsSpacing(1)};
  background: ${props => props.theme.palette.borderColors.default};
  border-radius: ${props => props.theme.config.borderRadius};

  svg {
    margin-left: ${props => props.theme.dsSpacing(2)};
  }
`;
