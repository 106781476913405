import { withTheme } from 'styled-components';

import { getMatchMediaQuery } from 'Utilities/matchMedia';

const MatchMediaQuery = ({ children, minWidth, maxWidth, theme }) => {
  const matches = getMatchMediaQuery({
    minWidth: theme.config.breakpoints[minWidth],
    maxWidth: theme.config.breakpoints[maxWidth],
  });

  if (typeof children === 'function') {
    return children(matches);
  }
  return matches ? children : null;
};

export default withTheme(MatchMediaQuery);
