export default {
  lightenRatio: 0.3,
  darkenRatio: 0.3,
  disabledOpacity: 0.3,
  spacing: 4,
  dsSpacing: 4,
  zIndex: {
    drawer: 400,
    modal: 500,
    tooltip: 700,
    toast: 800,
    dropdown: 600,
  },
  breakpoints: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  },
  borderRadius: '4px',
  font: {
    rootSize: 16,
    family: 'Montserrat,sans-serif',
  },
  shadows: [
    '-6px 0 white, 6px 0 white, 0 2px 8px #f0f1f2',
    '0px 0px 20px #f0f1f2',
    '0 25px 50px -12px #f0f1f2',
    '0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04)', // box shadow for date picker
  ],
};
