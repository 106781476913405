import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from 'Components/Icons';

import {
  ProgressBarWrapper,
  ProgressBarLine,
  ProgressValue,
  CircularProgressBarWrapper,
} from './styled';

const Circular = ({ variant, textProps, showText, ...props }) => (
  <CircularProgressBarWrapper>
    <CircularProgress {...props} />
    {showText && (
      <ProgressValue
        center
        variant={variant}
        color={props.color}
        {...textProps}
      >
        {props.value}%
      </ProgressValue>
    )}
  </CircularProgressBarWrapper>
);

const Line = ({ variant, textProps, showText, ...props }) => (
  <ProgressBarWrapper {...props}>
    <ProgressBarLine {...props} />
    {showText && (
      <ProgressValue
        color={props.color}
        variant={variant}
        align="center"
        {...textProps}
      >
        {props.value}%
      </ProgressValue>
    )}
  </ProgressBarWrapper>
);

const TYPES = {
  circular: Circular,
  line: Line,
};

const ProgressBar = ({ type, ...props }) => {
  const Comp = TYPES[type];
  return <Comp {...props} />;
};

ProgressBar.defaultProps = {
  color: 'primary',
  value: 0,
  thickness: 10,
  size: '130',
  variant: 'h1',
  textProps: {},
};

ProgressBar.propTypes = {
  color: PropTypes.string,
  value: PropTypes.number,
  thickness: PropTypes.number,
  size: PropTypes.number,
  variant: PropTypes.string,
};

export default ProgressBar;
