import React from 'react';
import { Drawer } from 'Components/UI';

import AppIcons from 'Components/AppIcons';
import AppLogo from 'Components/AppIcons/Logo';

import MenuBar from '../MenuBar';

import {
  AppDrawerWrapper,
  DrawerHeader,
  ToggleWrapper,
  MenuWrapper,
} from './styled';

const AppDrawer = ({ match, sidebarOpen, toggleSidebar }) => {
  return (
    <Drawer
      maskClosable
      isHoverTransition
      visible={sidebarOpen}
      onClose={toggleSidebar}
    >
      <AppDrawerWrapper>
        <DrawerHeader>
          <AppLogo width="10em" />
          <ToggleWrapper onClick={toggleSidebar}>
            <AppIcons name="toggleMenuIcon" width="1.7em" height="1.7em" />
          </ToggleWrapper>
        </DrawerHeader>
        <MenuWrapper onClick={toggleSidebar}>
          <MenuBar match={match} showLabel subMenu />
        </MenuWrapper>
      </AppDrawerWrapper>
    </Drawer>
  );
};

export default AppDrawer;
