import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _findIndex from 'lodash/findIndex';

import { Check } from 'Components/Icons';

import { Input } from '../Form/Input';
import { Typography } from '../Typography';
import { DropdownListWrapper, ListWrapper, ListItem } from './styled';

const DropdownList = ({
  hasOpen,
  multiple,
  searchPlaceholder,
  isLoading,
  list,
  selectedValue,
  onSearchDebounced,
  onClickListItem,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    if (!hasOpen) {
      setInputValue('');
    }
  }, [hasOpen]);

  const onChangeInput = event => {
    const { value } = event.target;
    setInputValue(value);
    onSearchDebounced(value);
  };
  return (
    <DropdownListWrapper multiple={multiple} {...props}>
      <Input
        placeholder={searchPlaceholder}
        value={inputValue}
        onChange={onChangeInput}
        autoFocus
        block
        {...props}
      />

      <ListWrapper data-testid="dropdown-list">
        {isLoading
          ? 'Loading...'
          : list.map(({ value, text }) => {
              const isActive = _findIndex(selectedValue, { value }) > -1;
              return (
                <ListItem
                  key={value}
                  active={isActive}
                  data-value={value}
                  data-text={text}
                  onClick={onClickListItem}
                >
                  <Typography
                    element="span"
                    variant="label"
                    weight={isActive ? '600' : 'normal'}
                  >
                    {text}
                  </Typography>
                  {isActive && <Check width="20" />}
                </ListItem>
              );
            })}
      </ListWrapper>
    </DropdownListWrapper>
  );
};

DropdownList.defaultProps = {
  searchPlaceholder: 'Search here',
  isLoading: false,
  list: [],
  selectedValue: '',
};

DropdownList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.any,
      value: PropTypes.any,
    }),
  ),
  isLoading: PropTypes.bool,
  selectedValue: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.any,
      value: PropTypes.any,
    }),
  ),
  searchPlaceholder: PropTypes.string,
  onSearchDebounced: PropTypes.func.isRequired,
  onClickListItem: PropTypes.func.isRequired,
};

export default DropdownList;
