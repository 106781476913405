import styled, { css } from 'styled-components';

export const RadioGroupWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  ${props => props.disabled && css`
    opacity: ${props.theme.config.disabledOpacity};
  `};
`;

