import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _keyBy from 'lodash/keyBy';
import _isEmpty from 'lodash/isEmpty';
import { Cross, Check } from 'Components/Icons';

import { Typography } from '../Typography';
import { BaseDropDown } from '../BaseDropDown';

import { SelectWrapper, DropdownWrapper, Options, Tags, Tag } from './styled';

const DEFAULT_OPTION = ({
  value,
  activecolor,
  color,
  text,
  isSelected,
  ...props
}) => {
  return (
    <Options
      key={value}
      color={isSelected ? activecolor : color}
      isSelected={isSelected}
      activecolor={activecolor}
      {...props}
    >
      {text}
      {isSelected && <Check />}
    </Options>
  );
};

const Select = ({
  options,
  defaultValue,
  onChange,
  placeholder,
  value,
  multiple,
  color,
  activecolor,
  defaultIsOpen,
  children,
  disabled,
  recordData,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const [selectedValue, setSelectedValue] = useState(value || defaultValue);
  const [selectOptions, setSelectOptions] = useState(false);

  useEffect(() => {
    if (typeof value !== 'undefined') {
      setSelectedValue(value);
    }
  }, [value]);

  useEffect(() => {
    setSelectOptions(_keyBy(options, 'value'));
  }, [options]);

  const addMultipleValue = val => {
    const copyValue = selectedValue ? [...selectedValue] : [];
    if (copyValue.includes(val)) {
      return copyValue.filter(v => v !== val);
    }
    return [...copyValue, val];
  };

  const onChangeValue = optionValue => {
    if (disabled) {
      return;
    }
    const newValue = multiple ? addMultipleValue(optionValue) : optionValue;

    if (typeof value === 'undefined') {
      setSelectedValue(newValue);
    }

    if (onChange) {
      onChange(newValue, recordData);
    }

    if (!multiple) {
      setIsOpen(false);
    }
  };

  const onClickOptions = optionValue => {
    onChangeValue(optionValue);
  };

  const onRemoveTag = id => {
    event.stopPropagation();
    onChangeValue(id);
  };

  return (
    <SelectWrapper hasList={multiple && !_isEmpty(selectedValue)} {...props}>
      <BaseDropDown
        disabled={disabled}
        isOpen={isOpen}
        onChangeIsOpen={setIsOpen}
        className="dropdown-input"
        dropDownProps={{ className: 'select-dropdown' }}
        inputComponent={
          multiple ? (
            _isEmpty(selectedValue) || !selectOptions ? (
              placeholder
            ) : (
              <Tags>
                {selectedValue.map(v => (
                  <Tag key={v}>
                    {selectOptions[v].text}{' '}
                    <Cross
                      data-id={v}
                      onClick={() => onRemoveTag(v)}
                      width="0.8em"
                    />
                  </Tag>
                ))}
              </Tags>
            )
          ) : (
            <Typography element="span" color="dark" truncate>
              {selectOptions[selectedValue]
                ? selectOptions[selectedValue].text
                : placeholder}
            </Typography>
          )
        }
      >
        <DropdownWrapper>
          {options.map(({ text, value }) => {
            return children({
              value,
              activecolor,
              color,
              text,
              multiple,
              onClick: () => onClickOptions(value),
              isSelected: multiple
                ? selectedValue && selectedValue.includes(value)
                : selectedValue === value,
            });
          })}
        </DropdownWrapper>
      </BaseDropDown>
    </SelectWrapper>
  );
};

Select.defaultProps = {
  color: 'black',
  activecolor: 'primary',
  recordData: null,
  children: DEFAULT_OPTION,
  defaultIsOpen: false,
  disabled: false,
};

Select.propTypes = {
  color: PropTypes.string,
  recordData: PropTypes.object,
  activecolor: PropTypes.string,
  defaultIsOpen: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Select;
