import { LS_KEY_CNST } from 'Utilities/constants/user';

export const saveUserData = user => {
  const jsonData = JSON.stringify(user);
  localStorage.setItem('userInfo', jsonData);
  localStorage.setItem('userObj', jsonData);
};

export const applyDefaultLoginObj = userObj => {
  if (userObj) {
    saveUserData(userObj);
    return userObj;
  }
  // getting v1 site loggedin user's localStorage
  const localStorageUserObj = localStorage.userObj
    ? JSON.parse(localStorage.userObj)
    : null;
  if (localStorageUserObj && localStorageUserObj.userId) {
    // Setting v1 loggedin user
    saveUserData(localStorageUserObj);
  }
  return JSON.parse(localStorage.getItem('userInfo')) || null;
};

export const removeUser = () => {
  localStorage.removeItem('userObj');
  localStorage.removeItem('userInfo');
  localStorage.removeItem('userFacilityId');
};

export const setUserSelectedState = stateObj => {
  if (!stateObj) {
    return;
  }
  if (stateObj.id) {
    const userObj = JSON.parse(localStorage.getItem('userInfo'));
    userObj.state = stateObj.id;
    saveUserData(userObj);
  }

  localStorage.setItem('cachedState', JSON.stringify(stateObj));
};

export const getUserSelectedState = () => {
  const userObj = localStorage.userInfo
    ? JSON.parse(localStorage.userInfo)
    : null;

  if (userObj && userObj.userId) {
    const states = userObj[LS_KEY_CNST.ALLOWED_STATE_LIST];
    if (!(states && states.length)) {
      return null;
    }

    const cachedState = localStorage.cachedState
      ? JSON.parse(localStorage.cachedState)
      : null;

    if (!cachedState) {
      return states[0];
    }

    if (states.length === 1) {
      return states[0];
    }
    return states.find(s => s.id === cachedState.id) || states[0];
  }
  return null;
};
