import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '../../index';
import { SwitchComponent, SwitchText } from './styled';

const Text = ({
  checked,
  checkedChildren,
  unCheckedChildren,
  ...restProps
}) => {
  const children = (checked && checkedChildren) || (!checked && unCheckedChildren);
  if (!children) {
    return null;
  }
  return (
    <SwitchText checked={checked} {...restProps}>
      {children}
    </SwitchText>
  );
};

const SWITCH_COMPONENT = ({
  unCheckedChildren,
  checkedChildren,
  checked,
  size,
  textProps,
  ...restProps
}) => {
  return (
    <SwitchComponent size={size} checked={checked} {...restProps}>
      <Text
        checked={checked}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        {...textProps}
      />
    </SwitchComponent>
  );
};

const Switch = ({
  unCheckedChildren,
  checkedChildren,
  size,
  textProps,
  ...restProps
}) => (
  <Checkbox {...restProps}>
    {props =>
      SWITCH_COMPONENT({
        size,
        unCheckedChildren,
        checkedChildren,
        textProps,
        ...props,
      })
    }
  </Checkbox>
);

Switch.defaultProps = {
  color: 'primary',
  unCheckColor: 'default',
  disabled: false,
  size: 'medium',
  textProps: {},
};

Switch.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  checkedChildren: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  unCheckedChildren: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default Switch;
