import React from 'react';
import { Icon } from 'Components/Icons';

const InwardHistory = props => (
  <Icon viewBox="0 0 24 24" {...props} fill="none">
    <path
      d="M13 3a9 9 0 00-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.954 8.954 0 0013 21a9 9 0 000-18zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8H12z"
      fill="currentColor"
    />
  </Icon>
);

export default InwardHistory;
