import React from 'react';
import { withTheme } from 'styled-components';

import { lighten } from 'Components/Utils';

import { Icon } from './index';

const CircularProgressSVG = ({ size, value, ...props }) => {
  const colorValue = props.theme.palette.colors[props.color];
  return (
    <Icon
      viewBox="0 0 100 100"
      strokeWidth="4"
      fill="transparent"
      height={size}
      width={size}
      {...props}
    >
      <circle cx="50" cy="50" r="48" stroke={lighten(colorValue, 0.7)} />
      <circle
        cx="50"
        cy="50"
        r="48" //r=cx-'stroke-width'/2
        stroke={colorValue} //progressColor
        strokeDasharray="301.44" //circumference=2*pi*r
        strokeDashoffset={`${301.44 * (1 - value / 100)}`}
        //subtract percentage from 1 because length of the offset is inversely proportional to the value of progress
        strokeLinecap="round"
        transform="rotate(-90 50 50)" //to start progress value from top
      />
    </Icon>
  );
};

export const CircularProgress = withTheme(CircularProgressSVG);
