import styled, { css } from 'styled-components';

import { Typography } from '../Typography';

export const TabMenu = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid ${props => props.theme.palette.borderColors.divider};
`;

export const activeTabCSS = css`
  color: ${props => props.theme.palette.colors[props.activecolor]};

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 5px;
    background: ${props => props.theme.palette.colors[props.activecolor]};
    content: '';
  }
`;

export const TabMenuItem = styled(Typography)`
  position: relative;
  margin: ${props => props.theme.spacing(0, 1, 0, 0)};
  padding: ${props => props.theme.spacing(2)};
  text-decoration: none;
  cursor: pointer;

  &.${props => props.activeclass} {
    ${activeTabCSS}
  }
  ${props => props.active && activeTabCSS}
`;
