import React from 'react';
import PropTypes from 'prop-types';

import { CheckSquare } from 'Components/Icons';

import { SwitchBase } from '../../index';

const DEFAULT_COMPONENT = ({ checked, color, unCheckColor, size }) => (
  <CheckSquare
    height={size}
    width={size}
    checked={checked}
    color={checked ? color : unCheckColor}
  />
);

const Checkbox = props => (
  <SwitchBase type="checkbox" {...props} />
);

Checkbox.defaultProps = {
  color: 'primary',
  disabled: false,
  children: DEFAULT_COMPONENT,
  size: '1.5em',
};

Checkbox.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

export default Checkbox;
