import React from 'react';

import { Icon } from './index';

export const Arrow = ({ direction, ...props }) => (
  <Icon
    viewBox="0 0 10 10"
    transform={
      direction ? (direction === 'up' ? 'rotate(180)' : 'rotate(0)') : ''
    }
    {...props}
  >
    {direction ? (
      <path d="M5 7.99L1.5 4.5l1-1L5 6.01 7.5 3.5l.99 1L5 7.99z"></path>
    ) : (
      <path d="M5 8L3 6 2 7l3 3 3-3-1-1zm0-6l2 2 1-1-3-3-3 3 1 1z"></path>
    )}
  </Icon>
);

export default Arrow;
