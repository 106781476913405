import {
  startOfWeek,
  startOfDay,
  startOfMonth,
  subDays,
  endOfMonth,
  endOfWeek,
  format,
} from 'date-fns';

const getCurrentDate = () => new Date();

export const dateRange = {
  today: () => ({
    startDate: startOfDay(getCurrentDate()),
    endDate: getCurrentDate(),
  }),
  thisWeek: () => ({
    startDate: startOfWeek(getCurrentDate()),
    endDate: getCurrentDate(),
  }),
  lastWeek: () => ({
    startDate: startOfWeek(subDays(getCurrentDate(), 7)),
    endDate: endOfWeek(subDays(getCurrentDate(), 7)),
  }),
  thisMonth: () => ({
    startDate: startOfMonth(getCurrentDate()),
    endDate: getCurrentDate(),
  }),
  lastMonth: () => ({
    startDate: startOfMonth(subDays(getCurrentDate(), 30)),
    endDate: endOfMonth(subDays(getCurrentDate(), 30)),
  }),
};

export const formatDate = (date, dateFormat = 'dd/MM/yyyy') =>
  format(date, dateFormat);

export default dateRange;
