import React from 'react';
import { Icon } from 'Components/Icons';

const Payments = props => (
  <Icon viewBox="0 0 24 24" {...props} fill="none">
    <path opacity=".3" d="M4 6h16v2H4V6zm0 6h16v6H4v-6z" fill="currentColor" />
    <path
      d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"
      fill="currentColor"
    />
  </Icon>
);

export default Payments;
