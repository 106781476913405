import React from 'react';
import { Loader } from 'Components/UI';

import { LoaderWrapper, LoadingBarWrapper } from './styled';

const AppLoader = () => (
  <LoaderWrapper>
    <Loader type="circle" color="primary" />
  </LoaderWrapper>
);

export const AppLoadingBar = () => (
  <LoadingBarWrapper role="progressbar">
    <span />
  </LoadingBarWrapper>
);

export default AppLoader;
