import { Badges } from './styled';
import PropTypes from 'prop-types';

Badges.defaultProps = {
  color: 'primary',
  element: 'span',
  borderRadius: 30,
};

Badges.propTypes = {
  color: PropTypes.string,
  element: PropTypes.string,
  borderRadius: PropTypes.number,
};
export default Badges;
