import React from 'react';
import { Icon } from 'Components/Icons';

const PartnersTransport = props => (
  <Icon viewBox="0 0 24 24" {...props} fill="none">
    <path
      d="M16.49 13.75L14 16.25l2.49 2.5V17H22v-1.5h-5.51v-1.75zm3.02 6H14v1.5h5.51V23L22 20.5 19.51 18v1.75zM7.5 3.5c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2zm2.05 6.9C10.85 12 12.8 13 15 13v-2c-1.85 0-3.45-1-4.35-2.45l-.95-1.6C9.35 6.35 8.7 6 8 6c-.25 0-.5.05-.75.15L2 8.3V13h2V9.65l1.75-.75L3 23h2.1l1.75-8L9 17v6h2v-7.55L8.95 13.4l.6-3z"
      fill="currentColor"
    />
  </Icon>
);

export default PartnersTransport;
