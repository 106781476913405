import React from 'react';
import { Icon } from 'Components/Icons';

const SubMenu = props => (
  <Icon viewBox="0 0 9 9" {...props} fill="currentColor">
    <circle
      cx="4.5"
      cy="4.5"
      r="3.5"
      fill="transparent"
      stroke="currentColor"
      strokeWidth="2"
    />
    <circle
      cx="4.5"
      cy="4.5"
      r="1"
      opacity=".5"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="3"
    />
  </Icon>
);

export default SubMenu;
