import React from 'react';
import { Icon } from 'Components/Icons';

const AssignedPackages = props => (
  <Icon viewBox="0 0 24 24" {...props} fill="none">
    <path
      opacity=".3"
      d="M5 19h14V5H5v14zm2.41-7.41L10 14.17l6.59-6.59L18 9l-8 8-4-4 1.41-1.41z"
      fill="currentColor"
    />
    <path
      d="M18 9l-1.41-1.42L10 14.17l-2.59-2.58L6 13l4 4 8-8zm1-6h-4.18C13.878.398 10.122.398 9.18 3H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.24-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"
      fill="currentColor"
    />
  </Icon>
);

export default AssignedPackages;
