import { lmdService, dashboardService } from 'Services/base';

export const dashboardWidgets = params => {
  return lmdService.post('dashboard/', params);
};

export const dashboardRequest = (endpoint, body) =>
  dashboardService.post(`${endpoint}/`, body);

export const dashboardGetRequest = (endpoint, params) =>
  dashboardService.get(endpoint, { params });

export const dashboardDownloadRequest = (endpoint, body) =>
  dashboardService.post(`${endpoint}/`, {
    ...body,
    sendData: true,
    sendFile: true,
  });

export const userDashboardPreference = () => {
  return dashboardService.get(`userdashboard/`);
};

export const saveUserDashboardPreference = (username, params) => {
  return dashboardService.post(`userdashboard/${username}/`, params);
};

export const drillDownRequest = params => {
  return dashboardService.post(`drilldown/`, params);
};

export const payableDrillDownRequest = params => {
  return dashboardService.post(`payablesdrilldown/`, params);
};

export const skuDrillDownRequest = params => {
  return lmdService.get('skudrilldown', { params });
};

export const partnerWiseSKURequest = params => {
  return lmdService.get('partnerwisesku', { params });
};

// DrillDown API
export const overviewDrillDownRequest = status => {
  return facility =>
    drillDownRequest({
      filters: {
        deliveryStatus: status,
        facility,
      },
      groupBy: 'franchiseGroup',
      sendData: true,
    });
};

export const partnerDrillDownRequest = (status, facility, franchiseGroup) => ({
  url: 'drilldown',
  reqBody: {
    filters: {
      deliveryStatus: status,
      facility,
      franchiseGroup,
    },
    groupBy: 'franchise',
    sendData: true,
  },
});

export const overviewPayableDrillDownRequest = () => {
  return facility =>
    payableDrillDownRequest({
      filters: {
        facility,
      },
      groupBy: 'franchiseGroup',
      sendData: true,
    });
};

export const partnerPayableDrillDownRequest = (facility, franchiseGroup) => ({
  url: 'payablesdrilldown',
  reqBody: {
    filters: {
      facility,
      franchiseGroup,
    },
    groupBy: 'franchise',
    sendData: true,
  },
});
