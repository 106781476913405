import styled, { css } from 'styled-components';

export const SwitchComponent = styled.span`
  position: relative;
  display: inline-block;
  color: ${props => props.theme.palette.contrastText[props.checked ? props.color : props.unCheckColor]};
  vertical-align: middle;
  background: ${props => props.theme.palette.colors[props.checked ? props.color : props.unCheckColor]};
  border-radius: 15px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background 0.3s ease;

  ${props =>
    props.size === "small"
      ? css`
          min-width: 28px;
          height: 16px;
          line-height: 15px;
          font-size: 10px;
        `
      : css`
          min-width: 44px;
          height: 22px;
          line-height: 23px;
          font-size: 12px;
        `};

  &::after {
    position: absolute;
    top: 2px;
    ${props =>
      props.checked
        ? css`
            left: 100%;
            transform: translateX(-100%);
            margin-left: -2px;
          `
        : css`
            left: 1px;
          `};

    ${props =>
      props.size === "small"
        ? css`
            width: 12px;
            height: 12px;
          `
        : css`
            width: 18px;
            height: 18px;
          `};
    background: white;
    border-radius: 50%;
    transition: all 0.3s ease;
    content: '';
  }
`;

export const SwitchText = styled.span`
  margin: ${props => props.checked ? '0px 24px 0px 8px' : '0px 6px 0px 26px'};
`;
