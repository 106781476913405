import React from 'react';
import { Icon } from 'Components/Icons';

const ReturnsHistory = props => (
  <Icon viewBox="0 0 27 23" {...props} fill="none">
    <g clipPath="url(#clip0)" fill="currentColor">
      <path d="M17 16H3.83l3.58-3.59L6 11l-6 6 6 6 1.41-1.41L3.83 18H19l-2-2z" />
      <path d="M18 0a9 9 0 00-9 9H6l3.89 3.89.07.14L14 9h-3c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-6.706 0-6.492 2 .5 2 4.97 0 8.5-4.03 8.5-9a9 9 0 00-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V5H17z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="currentColor" d="M0 0h27v23H0z" />
      </clipPath>
    </defs>
  </Icon>
);

export default ReturnsHistory;
