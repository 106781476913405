import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  :root {
    box-sizing: border-box;
    font-size: ${props => props.theme.config.font.rootSize}px;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  ul,
  ol {
    padding: 0;
    list-style: none;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
  }

  body {
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    color: ${props => props.theme.palette.colors.text};
    font-family: ${props => props.theme.config.font.family};
    ${props => props.theme.typography.body}
    background-color: ${props => props.theme.palette.colors.body};
  }

  *::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
 
  *::-webkit-scrollbar-track {
    background: #C4C4C4;
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
 
  *::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  *::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 0, 0, 0.4);
  }

  .highcharts-credits {
    display: none;
  }

  /* TODO: remove unwanted code after v3 design integration */

  table {
    border-spacing: 0;
  }

  .agTooltip {
    border: 1px solid #999 !important;
    opacity: 1 !important;
  }

  .agTooltip.place-top::after,
  .agTooltip.place-bottom::after {
    border-color: #999 transparent !important;
  }

  .agTooltip.place-right::after,
  .agTooltip.place-left::after {
    border-color: transparent #999 !important;
  }

  .hidden {
    display: none;
  }

  .margin-horizontal {
    margin: ${props => props.theme.spacing(0, 1)};
  }

  .pointer {
    cursor: pointer;
  }

  .saathi {
    background: #FEF3D9 !important;
  }
`;

export default GlobalStyle;
