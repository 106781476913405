import React from 'react';

import TreeContext from './TreeContext';
import { ListNode, ListChild, ListNodeContent } from './styled';

class TreeItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: props.defaultOpen,
      isFirstLevelOpen: props.firstLevelChildrenOpen,
    };

    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onChangeExpand = this.onChangeExpand.bind(this);
  }

  componentDidMount() {
    const { data, defaultSelected } = this.props;
    const { onChangeSelected, dataId } = this.context;

    const hasChildren = data.children && data.children.length;

    onChangeSelected({
      id: data[dataId],
      newValue: {
        checked: defaultSelected.includes(data[dataId]),
        children: hasChildren && data.children.map(c => c[dataId]),
      },
      notify: false,
    });
  }

  componentDidUpdate() {
    const { data } = this.props;
    const { onChangeSelected, selectedIds, dataId } = this.context;
    const id = data[dataId];

    if (selectedIds[id] && selectedIds[id].children) {
      const checkedAllChild = selectedIds[id].children.every(
        c => selectedIds[c].checked,
      );
      if (checkedAllChild !== selectedIds[id].checked) {
        onChangeSelected({
          id,
          newValue: {
            ...selectedIds[id],
            checked: checkedAllChild,
          },
        });
      }
    }
  }

  onChangeCheckbox(id, select) {
    const { data } = this.props;
    const { onChangeSelected, onChildrenUpdate, selectedIds } = this.context;

    const hasChildren = data.children && data.children.length;

    onChangeSelected({
      id,
      newValue: {
        ...selectedIds[id],
        checked: select,
      },
      reset: true,
    });

    if (hasChildren) {
      onChildrenUpdate(id, select);
    }
  }

  onChangeExpand() {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded,
      isFirstLevelOpen: !prevState.isFirstLevelOpen,
    }));
  }

  render() {
    const { data, component, isSelected, defaultSelected } = this.props;
    const { isExpanded, isFirstLevelOpen } = this.state;

    const { dataId, selectedIds } = this.context;

    const id = data[dataId];
    const hasChildren = data.children && data.children.length;

    return (
      <ListChild>
        <ListNodeContent hasChildren={hasChildren}>
          {component({
            id,
            hasChildren,
            isExpanded,
            isFirstLevelOpen,
            isSelected,
            onChangeExpand: this.onChangeExpand,
            onChangeSelect: this.onChangeCheckbox,
          })}
        </ListNodeContent>
        {hasChildren
          ? data.children.map(d => (
              <ListNode key={d[dataId]} show={isExpanded}>
                <TreeItem
                  data={d}
                  isSelected={
                    selectedIds[d[dataId]] && selectedIds[d[dataId]].checked
                  }
                  defaultSelected={defaultSelected}
                  component={component}
                  defaultOpen={isFirstLevelOpen}
                  firstLevelChildrenOpen={false}
                />
              </ListNode>
            ))
          : null}
      </ListChild>
    );
  }
}

TreeItem.contextType = TreeContext;

export default TreeItem;
