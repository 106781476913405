import React, { useState } from 'react';
import { Eye } from 'Components/Icons';

import Input from './Input';

const PasswordInput = ({ suffix, prefix, addons, iconProps, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const onClickEye = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Input
      {...props}
      type={showPassword ? 'text' : 'password'}
      suffix={
        <Eye
          width="1.7em"
          visible={showPassword}
          onClick={onClickEye}
          {...iconProps}
        />
      }
    />
  );
};

export default PasswordInput;
