import React from 'react';
import { Icon } from 'Components/Icons';

const DrillDown = props => (
  <Icon viewBox="0 0 20 18" {...props} fill="none">
    <path
      opacity=".3"
      d="M9.25235 17.1583C9.65017 17.6061 10.3498 17.6061 10.7477 17.1583L17.1822 9.91409C17.7551 9.26911 17.2972 8.25 16.4345 8.25H3.5655C2.70282 8.25 2.24495 9.26911 2.81784 9.91409L9.25235 17.1583Z"
      fill="currentColor"
    />
    <path
      d="M9.25235 12.1583C9.65017 12.6061 10.3498 12.6061 10.7477 12.1583L17.1822 4.91409C17.7551 4.26911 17.2972 3.25 16.4345 3.25H3.5655C2.70282 3.25 2.24495 4.26911 2.81784 4.91409L9.25235 12.1583Z"
      fill="currentColor"
    />
  </Icon>
);

export default DrillDown;
