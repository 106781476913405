import React from 'react';

import { Icon } from './index';

export const Search = props => (
  <Icon viewBox="0 0 18 18" {...props}>
    <path
      fillRule="nonzero"
      strokeWidth=".9"
      d="M12.961 3.053c-2.717-2.737-7.17-2.737-9.908 0-2.737 2.738-2.737 7.191 0 9.908a7.025 7.025 0 0 0 9.622.286l3.83 3.83a.395.395 0 0 0 .572 0 .395.395 0 0 0 0-.572l-3.83-3.83c2.451-2.758 2.37-6.987-.286-9.622zm-.572 9.336c-2.41 2.41-6.353 2.41-8.764 0-2.41-2.41-2.41-6.353 0-8.764 2.41-2.41 6.353-2.41 8.764 0 2.41 2.41 2.41 6.353 0 8.764z"
    />
  </Icon>
);

export default Search;
