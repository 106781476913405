import styled, { css } from 'styled-components';

export const Wrapper = styled.span`
  position: relative;
  display: inline-flex;
  width: 100%;
`;

export const AffixWrapper = styled.span`
  ${props =>
    props.addons
      ? css`
          display: flex;
          align-items: center;
          padding: ${props => props.theme.dsSpacing(0, 2)};
          white-space: nowrap;
          background: ${props => props.theme.palette.colors.body};
        `
      : css`
          position: absolute;
          top: 50%;
          line-height: 0;
          transform: translateY(-50%);
        `}
  ${props =>
    props.left &&
    css`
      left: 10px;
      border-top-left-radius: ${props => props.theme.config.borderRadius};
      border-bottom-left-radius: ${props => props.theme.config.borderRadius};
    `}
  ${props =>
    props.right &&
    css`
      right: 10px;
      border-top-right-radius: ${props => props.theme.config.borderRadius};
      border-bottom-right-radius: ${props => props.theme.config.borderRadius};
    `}
`;

export const InputEle = styled.input`
  width: 100%;
  padding: ${props => props.theme.dsSpacing(2.5)};
  font-size: 0.8rem;
  border: 1px solid ${props => props.theme.palette.borderColors.default};
  border-radius: ${props => props.theme.config.borderRadius};
  outline: none;
  ${props =>
    props.addons
      ? css`
          ${props.suffix &&
            css`
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            `}
          ${props.prefix &&
            css`
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            `}
        `
      : css`
          ${props.suffix &&
            css`
              padding-right: ${props => props.theme.dsSpacing(7.5)};
            `}
          ${props.prefix &&
            css`
              padding-left: ${props => props.theme.dsSpacing(7.5)};
            `}
        `}
  ${props =>
    props.status
      ? css`
          border-color: ${props.theme.palette.colors[props.status]};
        `
      : css`
          &:focus {
            border-color: ${props => props.theme.palette.colors.info};
          }
        `}
`;
