import styled, { css } from 'styled-components';
import { lighten } from 'Components/Utils';

import { Typography } from '../Typography';

export const ProgressBarWrapper = styled.div`
  width: 100%;
  height: ${props => props.thickness}px;
  background: ${props => lighten(props.theme.palette.colors[props.color], 0.7)};
  border-radius: ${props => props.theme.config.borderRadius};
`;

export const ProgressBarLine = styled.div`
  width: ${props => props.value}%;
  height: ${props => props.thickness}px;
  background: ${props => props.theme.palette.colors[props.color]};
  border-radius: ${props => props.theme.config.borderRadius};
  transition: width 0.5s;
`;

export const CircularProgressBarWrapper = styled.div`
  position: relative;
  display: inline-block;

  circle {
    transition: all 0.5s;
  }
`;

export const ProgressValue = styled(Typography)`
  ${props =>
    props.center &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    `}
`;
