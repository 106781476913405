import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, AffixWrapper, InputEle } from './styled';

const Input = ({ forwardedRef, suffix, prefix, addons, ...props }) => {
  return (
    <Wrapper>
      {prefix && (
        <AffixWrapper addons={addons} left>
          {prefix}
        </AffixWrapper>
      )}
      <InputEle
        ref={forwardedRef || null}
        suffix={suffix}
        prefix={prefix}
        addons={addons}
        {...props}
      />
      {suffix && (
        <AffixWrapper addons={addons} right>
          {suffix}
        </AffixWrapper>
      )}
    </Wrapper>
  );
};

Input.forwardRef = React.forwardRef((props, ref) => (
  <Input forwardedRef={ref} {...props} />
));

Input.defaultProps = {
  addons: false,
};

Input.propTypes = {
  addons: PropTypes.bool, // handle input padding from app if addons is false in prefix and suffix
};

export default Input;
