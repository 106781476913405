import axios from 'axios';
import _get from 'lodash/get';

import { Toast } from 'Components/UI';
import { selectors } from 'State/user';

// eslint-disable-next-line import/no-cycle
import store from '../../store';

const agroexServiceConfig = {
  timeout: 45000,
  headers: {
    'Content-Type': 'application/json',
  },
};

const getServiceInstance = baseURL => {
  const serviceInstance = axios.create({
    ...agroexServiceConfig,
    ...{ baseURL },
  });

  serviceInstance.CancelToken = axios.CancelToken;
  serviceInstance.isCancel = axios.isCancel;

  serviceInstance.interceptors.request.use(config => {
    const state = store.getState();
    const modifiedConfig = { ...config };
    modifiedConfig.headers['X-Authorization-Token'] = modifiedConfig.headers['X-Authorization-Token'] || selectors.getAuthToken(
      state,
    );
    modifiedConfig.headers.state = selectors.getSelectedStateId(state);
    modifiedConfig.headers.facility = selectors.getSelectedStateId(state);
    return modifiedConfig;
  });

  serviceInstance.interceptors.response.use(
    response => {
      return response.data.responseData || response.data;
    },
    error => {
      if (serviceInstance.isCancel(error)) {
        return;
      }

      if (
        _get(error, 'response.status') === 401 &&
        (_get(error, 'response.statusText').toLowerCase() === 'unauthorized' ||
          _get(error, 'response.data.message').toLowerCase() ===
            'unauthorized.')
      ) {
        Toast('Unauthorized. Please login again...!!!', {
          type: Toast.TYPE.ERROR,
          duration: 15000,
        });
        localStorage.removeItem('userObj');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userFacilityId');

        
        let currentUrl = window.location.href;
        if (!currentUrl.includes('login')) {
          setTimeout(() => {
            window.location.href = `${
              window.location.href.split('#')[0]
            }/#/login`;
          }, 5000);
        }

      } else {
        Toast(_get(error, 'response.data.message') || 'Something went wrong, Please retry! ', {
          type: Toast.TYPE.ERROR,
          duration: 5000,
        });
        throw error;
      }
    },
  );
  return serviceInstance;
};

export const dashboardService = getServiceInstance(`${window.config.API_V3}dashboard/`);
export const lmdService = getServiceInstance(window.config.API_GATEWAY);
export const assignmentService = getServiceInstance(window.config.ASSIGNMENT_SERVICE);
export const lmdUIV2Service = getServiceInstance(window.config.LMD_UI_V2_API);
export const userService = getServiceInstance(window.config.USER_SERVICE);
export const lmdV3Service = getServiceInstance(window.config.API_V3);
export const lmdV4Service = getServiceInstance(window.config.API_V4);
