import { createSelector } from 'reselect';
import _pick from 'lodash/pick';
import _pickBy from 'lodash/pickBy';

import { USER_PERMISSION } from 'Utilities/constants/user';
import { Widgets, WIDGET_GROUPS } from 'Utilities/constants/appConfig';
import { getUserGroup, getAccessibleWidgetGroup } from '../user/selectors';

export const getWidgets = state => state.widgets;

export const getWidgetId = (state, props) => props.id;

export const getWidgetByID = createSelector(
  [getWidgets, getWidgetId],
  (widgets, widgetId) => {
    return { ...Widgets[widgetId], ...widgets[widgetId] };
  },
);

export const getAccessibleWidgets = createSelector(
  [getUserGroup, getAccessibleWidgetGroup],
  (group, widgetGroup) => {
    if (
      USER_PERMISSION[group].widgets &&
      USER_PERMISSION[group].widgets.length > 0
    ) {
      return _pick(Widgets, USER_PERMISSION[group].widgets);
    }

    if (
      widgetGroup &&
      widgetGroup.length === Object.keys(WIDGET_GROUPS).length
    ) {
      return Widgets;
    }

    if (widgetGroup && widgetGroup.length > 0) {
      return _pickBy(Widgets, w => widgetGroup.includes(w.groupName));
    }

    return {};
  },
);
