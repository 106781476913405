import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import {
  applyDefaultLoginObj,
  getUserSelectedState,
} from 'Utilities/localStorage';

import rootReducer from './state';

const PROD = process.env.NODE_ENV === 'production';
const initialState = {
  user: applyDefaultLoginObj() || {},
  selectedState: getUserSelectedState() || {},
};

const middlewares = [thunk];

if (!PROD) {
  const logger = require('redux-logger');
  middlewares.push(logger.createLogger());
}

const composeEnhancers =
  (!PROD && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares)),
);

export default store;
