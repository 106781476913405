import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import getTime from 'date-fns/getTime';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import getYear from 'date-fns/getYear';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';

import _range from 'lodash/range';

import { PACKAGE_SLOT_COLOR_CODE } from 'Utilities/constants/package';

export const HISTORY_SPAN_CNST = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  THIS_WEEK: 'This_Week',
  LAST_7_DAYS: 'Last_7_Days',
  THIS_MONTH: 'This_Month',
  LAST_MONTH: 'Last_Month',
};

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getDateTimeStamp = getTime;

export function getFormattedDateTimeWithSecond(dateTime) {
  return dateTime ? format(dateTime, 'dd MMM yyyy, hh:mm:ss a') : '';
}

export function getFormattedDateTimeWithISOFormat(dateTime) {
  return dateTime ? format(dateTime, 'yyyy-MM-dd HH:mm:ss') : '';
}

export function getFormattedDate(dateTime) {
  return dateTime ? format(dateTime, 'dd MMM yyyy') : '';
}

export function getMonthYearFormat(dateTime) {
  return dateTime ? format(dateTime, 'MMM-yyyy') : '';
}

export function getFormattedDateTime(dateTime) {
  return dateTime ? format(dateTime, 'dd MMM yyyy, hh:mm a') : '';
}

export function getCurrentDate() {
  return getFormattedDate(new Date());
}

export function getCurrentDateTimestamp() {
  return getDateTimeStamp(new Date());
}

export function getFormattedTime(dateTime) {
  return format(dateTime, 'h:hh a');
}

export function getCurrentNextMonthDate() {
  return addMonths(new Date(), 1);
}

export function getTodayDateTimeStamp() {
  return {
    startDate: getDateTimeStamp(startOfDay(new Date())),
    endDate: getDateTimeStamp(new Date()),
  };
}

export function getTodayDateTime() {
  return { startDate: startOfDay(new Date()), endDate: new Date() };
}
export function getStartDayTimestamp(date) {
  return getDateTimeStamp(startOfDay(new Date(date)));
}
export function getEndDayTimestamp(date) {
  return getDateTimeStamp(endOfDay(new Date(date)));
}

export function getStartOfMonth(date) {
  return getDateTimeStamp(startOfMonth(new Date(date)));
}
export function getEndOfMonth(date) {
  return getDateTimeStamp(endOfMonth(new Date(date)));
}

export function getThisMonthRange() {
  return { startDate: startOfMonth(new Date()), endDate: new Date() };
}

export function getlastTwoDays() {
  return { startDate: startOfDay(subDays(new Date(), 1)), endDate: new Date() };
}

export function packageSlotStatus(time, packageTime) {
  if (!time || !packageTime) {
    return '';
  }
  if (isBefore(time, packageTime)) {
    return PACKAGE_SLOT_COLOR_CODE.FUTURE;
  }
  if (isAfter(time, packageTime)) {
    return PACKAGE_SLOT_COLOR_CODE.MISSED;
  }
  return '';
}

export function cutOffTimeStatus(packageTime1, packageTime2) {
  if (isBefore(packageTime1, packageTime2)) {
    return -1;
  }
  if (isAfter(packageTime1, packageTime2)) {
    return 1;
  }
  return 0;
}

export function getYearByDate(date) {
  return getYear(date);
}

export function getYearsList(startYear = 2015) {
  const endYear = getYearByDate(addYears(new Date(), 1));
  return _range(startYear, endYear);
}

export default function DateUtils(historySpan) {
  const currentDate = new Date();

  switch (historySpan) {
    case HISTORY_SPAN_CNST.TODAY:
      return {
        startDate: startOfDay(currentDate),
        endDate: endOfDay(currentDate),
      };

    case HISTORY_SPAN_CNST.YESTERDAY:
      return {
        startDate: startOfDay(subDays(currentDate, 1)),
        endDate: endOfDay(subDays(currentDate, 1)),
      };

    case HISTORY_SPAN_CNST.THIS_WEEK:
      return {
        startDate: startOfWeek(currentDate),
        endDate: endOfDay(currentDate),
      };

    case HISTORY_SPAN_CNST.THIS_MONTH:
      return {
        startDate: startOfMonth(currentDate),
        endDate: endOfMonth(currentDate),
      };

    case HISTORY_SPAN_CNST.LAST_7_DAYS:
      return {
        startDate: startOfDay(subDays(currentDate, 6)),
        endDate: currentDate,
      };

    case HISTORY_SPAN_CNST.LAST_MONTH:
      return {
        startDate: startOfMonth(subMonths(currentDate, 1)),
        endDate: endOfMonth(subMonths(currentDate, 1)),
      };

    default:
      throw new TypeError(
        `No matching DateUtil Constant found for ${historySpan}`,
      );
  }
}
