import React from 'react';
import PropTypes from 'prop-types';

import { TabMenu, TabMenuItem } from './styled';

/**
 * LinkTab Component
 *
 * add routing based tabs.
 * pass NavLink(react-router-dom) in component to active routing tabs.
 */
const LinkTab = ({ tabs, component, className, ...props }) => {
  return (
    <TabMenu className={className}>
      {tabs.map(({ id, label, ...tabProps }) => {
        return (
          <TabMenuItem
            key={id}
            forwardedAs={component}
            {...props}
            {...tabProps}
          >
            {label}
          </TabMenuItem>
        );
      })}
    </TabMenu>
  );
};

LinkTab.defaultProps = {
  color: 'primary',
  activecolor: 'primary',
  activeclass: 'active',
  className: '',
};

LinkTab.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  activecolor: PropTypes.string,
  activeclass: PropTypes.string,
};

export default LinkTab;
