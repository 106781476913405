import React from 'react';

import { Icon } from './index';

export const Cross = props => (
  <Icon viewBox="0 0 30 32" aria-hidden="true" {...props}>
    <path d="M29.867 3.531L26.859.523 14.934 12.448 3.009.523.001 3.531l11.925 11.925L.001 27.381l3.008 3.008 11.925-11.925 11.925 11.925 3.008-3.008-11.925-11.925z" />
  </Icon>
);

export default Cross;
