import React from 'react';
import { Icon } from 'Components/Icons';

const ReturnRequest = props => (
  <Icon viewBox="0 0 24 24" {...props} fill="none">
    <path
      d="M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7h-2z"
      fill="currentColor"
    />
  </Icon>
);

export default ReturnRequest;
