import React from 'react';

import { Typography } from '../../index';

const HelpText = ({ children, ...restProps }) => (
  <Typography variant="subText" {...restProps}>
    {children}
  </Typography>
);

export default HelpText;
