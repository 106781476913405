import styled, { css } from 'styled-components';

import { Typography } from '../Typography';

export const ListNode = styled.ul`
  margin: 0;
  padding-left: ${props => props.theme.dsSpacing(10)};
  list-style: none;

  ${props =>
    !props.show &&
    css`
      display: none;
    `}
`;

export const ListChild = styled.li`
  margin: ${props => props.theme.dsSpacing(2, 0)};
`;

export const ListNodeContent = styled.div`
  display: flex;
  align-items: center;
  margin: ${props => props.theme.dsSpacing(2, 0)};

  ${props =>
    !props.hasChildren &&
    css`
      margin-left: ${props => props.theme.dsSpacing(4)};
    `}
`;

export const NodeLabel = styled(Typography)`
  margin-left: ${props => props.theme.dsSpacing(2)};
`;
