import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import _findIndex from 'lodash/findIndex';

import { useOnClickOutside } from 'Components/Utils';

import { Typography, BaseDropDown } from '../index';
import DropdownList from './DropdownList';
import { Wrapper, SelectBox } from './styled';

const DEBOUNCE_TIME = 250;

const DropdownWithSearch = ({
  title,
  multiple,
  isCloseable,
  onSearch,
  onSelectListItem,
  list,
  listProps,
  isOpen,
  error,
  selectedValues,
  ...props
}) => {
  const [hasOpen, setOpen] = useState(isOpen);
  const [filteredList, setFilteredList] = useState(list);
  const toggleDropdown = () => {
    isCloseable && setOpen(!hasOpen);
  };

  const ref = useOnClickOutside(() => {
    isCloseable && setOpen(false);
  });

  const onSearchDebounced = _debounce(searchValue => {
    if (onSearch) {
      onSearch(searchValue);
      return;
    }

    setFilteredList(
      list.filter(l =>
        l.text.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    );
  }, DEBOUNCE_TIME);

  useEffect(() => {
    setFilteredList(list);
  }, [list]);

  useEffect(() => {
    if (!hasOpen) {
      setFilteredList(list);
    }
  }, [hasOpen]);

  const onClickListItem = event => {
    const { value, text } = event.currentTarget.dataset;
    let updatedSelectedValues = [...selectedValues];

    if (_findIndex(selectedValues, { value }) > -1) {
      updatedSelectedValues = updatedSelectedValues.filter(
        sv => sv.value !== value,
      );
    } else if (!multiple && selectedValues.length > 0) {
      updatedSelectedValues = [{ value, text }];
    } else {
      updatedSelectedValues = updatedSelectedValues.concat([{ value, text }]);
    }
    onSelectListItem && onSelectListItem(updatedSelectedValues);
    !multiple && isCloseable && setOpen(!hasOpen);
  };

  return (
    <Wrapper ref={ref}>
      <BaseDropDown
        data-testid="dropdown-input"
        isOpen={hasOpen}
        onChangeIsOpen={toggleDropdown}
        error={error}
        inputComponent={
          <Typography element="span" color="dark" truncate>
            {(!multiple && (selectedValues[0] && selectedValues[0].text)) ||
              title}
          </Typography>
        }
        dropDownProps={{
          className: 'dropdown-area',
        }}
        {...props}
      >
        <DropdownList
          {...listProps}
          list={filteredList}
          onClickListItem={onClickListItem}
          selectedValue={selectedValues}
          onSearchDebounced={onSearchDebounced}
          multiple={multiple}
          hasOpen={hasOpen}
        />
      </BaseDropDown>
    </Wrapper>
  );
};

DropdownWithSearch.defaultProps = {
  title: 'Select',
  isOpen: false,
  onSearch: false,
  list: [],
  error: false,
  multiple: false,
  isCloseable: true,
};

DropdownWithSearch.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.any,
      value: PropTypes.any,
    }),
  ),
  listProps: PropTypes.shape({
    isLoading: PropTypes.bool,
    searchPlaceholder: PropTypes.string,
  }).isRequired,
  title: PropTypes.string,
  onSearch: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSelectListItem: PropTypes.func.isRequired,
  selectedValues: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.any,
      value: PropTypes.any,
    }),
  ),
  isOpen: PropTypes.bool,
  multiple: PropTypes.bool,
  isCloseable: PropTypes.bool,
  error: PropTypes.bool,
};

export default DropdownWithSearch;
