import React from 'react';
import { Icon } from 'Components/Icons';

const OutwardConsignments = props => (
  <Icon viewBox="0 0 24 24" {...props} fill="none">
    <path
      opacity=".3"
      d="M13.657 14c.19 0 .343.153.343.343 0 .305.37.458.585.242l2.231-2.231a.5.5 0 000-.708l-2.231-2.231a.343.343 0 00-.585.242c0 .19-.153.343-.343.343H6.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h7.157z"
      fill="currentColor"
    />
    <path
      d="M19.646 12.354a.5.5 0 000-.708l-6.792-6.792a.5.5 0 00-.854.353V7.5a.5.5 0 01-.5.5h-7a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h7a.5.5 0 01.5.5v2.293a.5.5 0 00.854.353l6.792-6.792zM6.5 14a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h7.157c.19 0 .343-.153.343-.343 0-.305.37-.458.585-.242l2.231 2.231a.5.5 0 010 .708l-2.231 2.231a.343.343 0 01-.585-.242.343.343 0 00-.343-.343H6.5z"
      fill="currentColor"
    />
  </Icon>
);

export default OutwardConsignments;
