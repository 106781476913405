import styled, { css } from 'styled-components';

export const TextareaElement = styled.textarea`
  padding: ${props => props.theme.dsSpacing(2.5)};
  font-size: 0.8rem;
  border: 1px solid ${props => props.theme.palette.borderColors.default};
  border-radius: ${props => props.theme.config.borderRadius};
  outline: none;
  ${props => !props.cols && css`
    width: 100%;
  `}
  ${props =>
    props.status
      ? css`
          border-color: ${props.theme.palette.colors[props.status]};
        `
      : css`
          &:focus {
            border-color: ${props => props.theme.palette.colors.info};
          }
        `}
`;
