import styled, { css } from 'styled-components';

export const DIRECTIONS = {
  top: 'column',
  left: 'row',
};

export const Wrapper = styled.div`
  display: ${props => (props.block ? 'flex' : 'inline-flex')};
  align-items: start;
  margin: ${props => props.theme.dsSpacing(1)};
  ${props => `flex-direction: ${DIRECTIONS[props.labelTextPlacement]};`}
  ${props =>
    props.disabled &&
    css`
      opacity: ${props.theme.config.disabledOpacity};
    `}

  .form-label {
    ${props => props.labelTextPlacement === 'top' && css`
      margin-bottom: ${props => props.theme.dsSpacing(1)};
      text-align: left;
      `}
    ${props => props.labelTextPlacement === 'left' && css`
      margin-right: ${props => props.theme.dsSpacing(1)};
      text-align: right;
      `}
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    ${props =>
      props.required &&
      css`
        &::after {
          display: inline-block;
          margin: ${props => props.theme.dsSpacing(0, 1)};
          color: ${props => props.theme.palette.colors.error};
          font-weight: normal;
          font-size: 1.3em;
          line-height: 0.5;
          content: '*';
        }
      `}
  }

  .form-help-text {
    line-height: 2;
  }
`;

export const ChildWrapper = styled.div`
  ${props =>
    props.block &&
    css`
      flex: 1;
      width: 100%;
    `};
`;
