import React from 'react';
import _difference from 'lodash/difference';

import { Arrow } from 'Components/Icons';

import { Checkbox } from '../Form/index';

import TreeContext from './TreeContext';
import TreeItem from './TreeItem';
import { ListNode, NodeLabel } from './styled';

const DEFAULT_CHILDREN = props => (
  <>
    {props.hasChildren ? (
      <Arrow
        width="1.3em"
        direction={props.isExpanded ? 'up' : 'down'}
        onClick={props.onChangeExpand}
      />
    ) : null}
    <Checkbox
      size="2em"
      checked={props.isSelected}
      onChange={e => props.onChangeSelect(props.id, e.target.checked)}
    />
    <NodeLabel onClick={props.onChangeExpand}>{props.id}</NodeLabel>
  </>
);

class TreeView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIds: {},
    };

    this.onChangeSelected = this.onChangeSelected.bind(this);
    this.onChildrenUpdate = this.onChildrenUpdate.bind(this);
    this.onChangeSelectedIds = this.onChangeSelectedIds.bind(this);
  }

  onChangeSelectedIds() {
    const { selectedIds } = this.state;
    const { onChangeSelectedValue, onChangeSelectedAllValue } = this.props;

    if (!onChangeSelectedValue && !onChangeSelectedAllValue) {
      return;
    }

    const checkedIds = Object.keys(selectedIds).filter(
      id => selectedIds[id].checked,
    );

    if (onChangeSelectedAllValue) {
      onChangeSelectedAllValue(checkedIds);
    }

    if (onChangeSelectedValue) {
      const child = checkedIds.reduce((acc, id) => {
        if (selectedIds[id].children) {
          return [...acc, ...selectedIds[id].children];
        }
        return acc;
      }, []);
      onChangeSelectedValue(_difference(checkedIds, child));
    }
  }

  onChangeSelected({ id, newValue, init = false, reset = false }) {
    this.setState(
      prevState => {
        return {
          selectedIds: {
            ...prevState.selectedIds,
            ...this.resetTreeValues(prevState.selectedIds, reset),
            [id]: newValue,
          },
        };
      },
      () => {
        if (!init) {
          this.onChangeSelectedIds();
        }
      },
    );
  }

  onChildrenUpdate(id, select) {
    this.setState(prevState => {
      return {
        selectedIds: {
          ...prevState.selectedIds,
          ...this.updateChildren(prevState.selectedIds[id], select),
        },
      };
    }, this.onChangeSelectedIds);
  }

  updateChildren(data, select) {
    const { selectedIds } = this.state;

    return data.children.reduce((acc, c) => {
      const updatedValue = {
        ...acc,
        [c]: {
          ...selectedIds[c],
          checked: select,
        },
      };
      if (selectedIds[c].children) {
        return {
          ...updatedValue,
          ...this.updateChildren(selectedIds[c], select),
        };
      }
      return updatedValue;
    }, {});
  }

  resetTreeValues(selectedIds, reset) {
    const { singleSelect } = this.props;
    if (reset && singleSelect) {
      return Object.keys(selectedIds).reduce((acc, id) => {
        return { ...acc, [id]: { ...selectedIds[id], checked: false } };
      }, {});
    }
    return {};
  }

  render() {
    const {
      dataId,
      children,
      data,
      defaultOpen,
      firstLevelChildrenOpen,
      defaultSelected,
      onChangeSelectedValue,
      onChangeSelectedAllValue,
      ...restProps
    } = this.props;
    const { selectedIds } = this.state;

    return (
      <TreeContext.Provider
        value={{
          selectedIds,
          onChangeSelected: this.onChangeSelected,
          onChildrenUpdate: this.onChildrenUpdate,
          dataId,
        }}
      >
        <ListNode show {...restProps}>
          {data.map(d => (
            <TreeItem
              key={d[dataId]}
              data={d}
              component={children}
              defaultOpen={defaultOpen}
              firstLevelChildrenOpen={firstLevelChildrenOpen}
              defaultSelected={defaultSelected}
              isSelected={
                selectedIds[d[dataId]] && selectedIds[d[dataId]].checked
              }
            />
          ))}
        </ListNode>
      </TreeContext.Provider>
    );
  }
}

TreeView.defaultProps = {
  defaultOpen: true,
  firstLevelChildrenOpen: false,
  defaultSelected: [],
  dataId: 'id',
  children: DEFAULT_CHILDREN,
};

export default TreeView;
