import { returnFranchiseList } from 'Services/consignment';
import { getFranchiseList, updateFranchise } from 'Services/franchise';
import _sortBy from 'lodash/sortBy';
import * as types from './types';

export const getReturnFranchiseList = () => dispatch => {
  return returnFranchiseList().then(
    function(response) {
      return dispatch({
        type: types.GET_RETURN_FRANCHISE_SUCCESS,
        payload: _sortBy(response.franchises || [], 'name'),
      });
    },
    function({ response: { data } }) {
      return dispatch({
        type: types.GET_RETURN_FRANCHISE_FAILURE,
        payload: data.message,
      });
    },
  );
};

export const fetchFranchiseList = fields => dispatch => {
  return getFranchiseList(fields)
    .then(response => {
      return dispatch({
        type: types.GET_RETURN_FRANCHISE_SUCCESS,
        payload: response || [],
      });
    })
    .catch(err => {
      return dispatch({
        type: types.GET_RETURN_FRANCHISE_FAILURE,
        payload: err.message,
      });
    });
};

export const updateFranchiseData = (franchise, franchiseId) => () => {
  return updateFranchise(franchise, franchiseId);
};

export const setSelectFranchise = data => dispatch => {
  return dispatch({
    type: types.SET_SELECTED_FRANCHISE,
    payload: data,
  });
};
