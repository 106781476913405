import React from 'react';
import { Icon } from 'Components/Icons';

const DeliveredPackages = props => (
  <Icon viewBox="0 0 24 24" {...props} fill="none">
    <path opacity=".3" d="M5 19h14V8H5v11z" fill="currentColor" />
    <path
      opacity=".3"
      d="M5 19h14V8H5v11zm3.066-5.822l2.22 2.027 5.648-5.178 1.209 1.116-6.857 6.286-3.429-3.143 1.209-1.108z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.15 3.55l1.39 1.68c.29.34.46.79.46 1.27V19c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V6.5c0-.48.17-.93.46-1.27l1.38-1.68C5.12 3.21 5.53 3 6 3h12c.47 0 .88.21 1.15.55zM17.76 5H6.24l-.8.97h13.13L17.76 5zM5 19h14V8H5v11zm3.066-5.822l2.22 2.027 5.648-5.178 1.209 1.116-6.857 6.286-3.429-3.143 1.209-1.108z"
      fill="currentColor"
    />
  </Icon>
);

export default DeliveredPackages;
