import styled, { keyframes, css } from 'styled-components';
import { fade } from 'Components/Utils';

const blowUpModal = keyframes`

  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`;

const fadeIn = keyframes`

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const BodyWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: ${props => props.theme.config.zIndex.modal};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  animation: ${fadeIn} 0.3s;

  ${props =>
    !!props.isMask &&
    css`
      background-color: ${props => fade(props.theme.palette.colors.black, 0.5)};
    `}

  ${props =>
    props.position === 'top' &&
    css`
      align-items: start;
      padding-top: 10%;
    `}

  &.modal-body-animation-exit {
    opacity: 1;
  }

  &.modal-body-animation-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 32px);
  /* comment due to height issue */
  /* max-height: 80%; */
  background-color: ${props => props.theme.palette.colors.white};
  border-radius: ${props => props.theme.config.borderRadius};
  animation: ${blowUpModal} 0.3s;

  &.modal-animation-exit {
    transform: scale(1);
  }

  &.modal-animation-exit-active {
    transform: scale(0);
    transition: transform 300ms;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${props => props.theme.palette.borderColors.default};
  border-top-left-radius: ${props => props.theme.config.borderRadius};
  border-top-right-radius: ${props => props.theme.config.borderRadius};

  svg {
    cursor: pointer;
  }
`;

export const ModalBody = styled.div`
  flex: 1;
  padding: ${props => props.theme.dsSpacing(4)};
  overflow: auto;
`;

export const ModalFooter = styled.div`
  display: flex;
  padding: ${props => props.theme.dsSpacing(4)};
  border-top: 1px solid ${props => props.theme.palette.borderColors.default};
  ${props =>
    props.reverse &&
    css`
      flex-direction: row-reverse;
    `}

  button {
    margin-left: ${props => props.theme.dsSpacing(3)};
  }
`;

export const HeaderChild = styled.div`
  flex: 1;
  padding: ${props => props.theme.dsSpacing(4, 0, 4, 4)};
`;

export const HeaderClose = styled.div`
  padding: ${props => props.theme.dsSpacing(4)};
`;
