import React, { useContext } from 'react';
import _isUndefined from 'lodash/isUndefined';
import _over from 'lodash/over';
import PropTypes from 'prop-types';

import { CheckCircle } from 'Components/Icons';

import { RadioGroupContext } from './RadioGroup';
import { SwitchBase } from '../../index';

const DEFAULT_COMPONENT = ({ checked, color, unCheckColor, size }) => (
  <CheckCircle
    height={size}
    width={size}
    checked={checked}
    color={checked ? color : unCheckColor}
  />
);

const Radio = props => {
  const groupContext = useContext(RadioGroupContext);

  const { value: contextValue, ...restContext } = groupContext || {};

  const { checked: isChecked, value, ...restProps } = {
    ...restContext,
    ...props,
  };

  const checked = (groupContext && _isUndefined(isChecked)) ? contextValue === value : isChecked;

  return (
    <SwitchBase type="radio" checked={checked} value={value} {...restProps} />
  );
};

Radio.defaultProps = {
  children: DEFAULT_COMPONENT,
  size: '1.5em',
};

Radio.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

export default Radio;
