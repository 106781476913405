import styled, { css } from 'styled-components';

export const UploadWrapper = styled.div`
  position: relative;
  display: inline-block;
  ${props => props.disabled && css`
    opacity: ${props.theme.config.disabledOpacity};
  `}
`;

export const FileInput = styled.input.attrs({
  type: 'file'
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: 0;
`;

export const FileList = styled.span`
  margin-left: ${props => props.theme.dsSpacing(2)};
`;
