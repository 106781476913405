import styled from 'styled-components';

// TODO: use border color from theme
export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60px;
  height: 100%;
  margin-right: 1px;
  overflow: auto;
  background: ${props => props.theme.palette.colors.white};
`;

export default SidebarWrapper;
