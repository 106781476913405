import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Arrow } from 'Components/Icons';
import { useOnClickOutside } from 'Components/Utils';

import { DropDownWrapper, DropDownArea, DropDownInput } from './styled';

const BaseDropDown = ({
  isOpen,
  onChangeIsOpen,
  status,
  children,
  inputComponent,
  dropDownProps,
  position,
  disabled,
  ...restProps
}) => {
  useEffect(() => {
    onChangeIsOpen(false);
  }, [disabled]);

  const onChangeOpen = () => {
    if (!disabled) {
      onChangeIsOpen(!isOpen);
    }
  };

  const ref = useOnClickOutside(() => {
    onChangeIsOpen(false);
  });

  return (
    <DropDownWrapper ref={ref} className="dropdown">
      <DropDownInput status={status} onClick={onChangeOpen} disabled={disabled} {...restProps}>
        {inputComponent}
        <Arrow className="arrow-icon" direction={isOpen ? 'up' : 'down'} />
      </DropDownInput>
      <DropDownArea isOpen={isOpen} position={position} {...dropDownProps}>
        {children}
      </DropDownArea>
    </DropDownWrapper>
  );
};

BaseDropDown.defaultProps = {
  isOpen: true,
  dropDownProps: {},
  disabled: false,
};

BaseDropDown.prototype = {
  isOpen: PropTypes.bool,
  position: PropTypes.string,
  disabled: PropTypes.bool,
};

export default BaseDropDown;
