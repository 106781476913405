import React from 'react';
import { Icon } from 'Components/Icons';

const InterFacilityDispatch = props => (
  <Icon viewBox="0 0 23 21" {...props} fill="none">
    <path
      d="M19 13l-.705.705 2.79 2.795H0v1h21.085l-2.79 2.795L19 21l4-4-4-4z"
      fill="currentColor"
    />
    <path
      d="M10.17 0L17 4.55V15.3H3V4.78L10.17 0zM12 13.3h3V5.46l-4.83-3.07L5 5.68v7.62h3v-6h4v6z"
      fill="currentColor"
    />
    <path
      opacity=".3"
      d="M12 13.3h3V5.46l-4.83-3.07L5 5.68v7.62h3v-6h4v6z"
      fill="currentColor"
    />
  </Icon>
);

export default InterFacilityDispatch;
