import React from 'react';
import PropTypes from 'prop-types';

import { UploadWrapper, FileInput, FileList } from './styled';

const Upload = ({ children, onChange, showFileName, files, ...restProps }) => {
  const onChangeFile = event => {
    if (onChange) {
      onChange(event, Array.from(event.target.files));
    }
  };

  return (
    <UploadWrapper>
      {children}
      <FileInput {...restProps} onChange={onChangeFile} />
      {showFileName && files.length > 0 && (
        <FileList>{files.map(f => f.name)}</FileList>
      )}
    </UploadWrapper>
  );
};

Upload.defaultProps = {
  showFileName: true,
  files: [],
};

Upload.propTypes = {
  showFileName: PropTypes.bool,
  files: PropTypes.array,
};

export default Upload;
