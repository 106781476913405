// constants
import {
  ShipPkgDeliveryStatusCNST,
  ShippingPackageStatusCNST,
} from 'constants/package';

import DateUtils, {
  getTodayDateTimeStamp,
  getThisMonthRange,
  getDateTimeStamp,
  HISTORY_SPAN_CNST,
} from 'Utilities/dateUtils';

const businessCoverageDateRange = DateUtils(HISTORY_SPAN_CNST.LAST_7_DAYS);
const deliveryTatDateRange = DateUtils(HISTORY_SPAN_CNST.THIS_MONTH);

export const TABS = {
  DASHBOARD: {
    label: 'Dashboard',
    url: 'dashboard',
    id: 'DASHBOARD',
    main: true,
    icon: 'dashboard',
  },
  REPORTS: {
    label: 'Reports',
    url: 'reports',
    id: 'REPORTS',
    main: true,
    icon: 'report',
  },
  REVERSE_LOGISTICS: {
    label: 'Reverse Logistics',
    url: 'reverse-logistic',
    id: 'REVERSE_LOGISTICS',
    icon: 'reverseLogistics',
  },
  DRILLDOWN: {
    label: 'DrillDown',
    url: 'drilldown',
    id: 'DRILLDOWN',
    icon: 'drilldown',
  },
  SEARCH: { label: 'Search', url: 'search/:id', id: 'SEARCH', invisible: true },
  MISSING_PACKAGES: {
    label: 'Missing Packages',
    url: 'missing-packages',
    id: 'MISSING_PACKAGES',
    icon: 'missing',
  },
  MISSING: {
    label: 'Missing',
    url: 'missing',
    id: 'MISSING',
    icon: 'missing',
  },
  DISPATCHED_TO_LP: {
    label: 'Dispatch To LP',
    url: 'dispatch-to-lp',
    id: 'DISPATCHED_TO_LP',
    icon: 'dispatchToLP',
  },
  INTER_FACILITY_DISPATCH: {
    label: 'Inter Facility Dispatch',
    url: 'inter-facility-dispatch',
    id: 'INTER_FACILITY_DISPATCH',
    icon: 'interFacilityDispatch',
  },
  OUTWARD_CONSIGNMENTS: {
    label: 'Outward Consignments',
    url: 'outward-consignment',
    id: 'OUTWARD_CONSIGNMENTS',
    icon: 'outwardConsignment',
  },
  PARTNERS_ACCOUNTS: {
    label: 'Partner Accounts',
    url: 'partners-accounts',
    id: 'PARTNERS_ACCOUNTS',
    icon: 'partnerAccounts',
  },
  OUTGOING_PAYMENT: {
    label: 'Outgoing Payments',
    url: 'outgoing-payment',
    id: 'OUTGOING_PAYMENT',
    icon: 'outwardPayment',
  },
  PARTNERS_PROFILE: {
    label: 'Partners List',
    url: 'partners-profile',
    id: 'PARTNERS_PROFILE',
    icon: 'partnersProfile',
  },
  TRANSPORTS: {
    label: 'Transports List',
    url: 'transports',
    id: 'TRANSPORTS',
    icon: 'partnersTransport',
  },
  ASSIGNED_PACKAGES: {
    label: 'Assigned Packages',
    url: 'assigned-packages',
    id: 'ASSIGNED_PACKAGES',
    icon: 'assignedPackages',
  },
  SAATHI_PACKAGES: {
    label: 'Saathi Packages',
    url: 'saathi-packages',
    id: 'SAATHI_PACKAGES',
    icon: 'saathiRupee',
  },
  PENDING_ASSIGNMENT: {
    label: 'Pending Assignment',
    url: 'pending-assignment',
    id: 'PENDING_ASSIGNMENT',
    icon: 'assignments',
  },
  RETURN_REQUESTS: {
    label: 'Return Requests',
    url: 'return-requests',
    id: 'RETURN_REQUESTS',
    icon: 'returnRequest',
  },
  INCOMING_PAYMENT: {
    label: 'Incoming Payments',
    url: 'incoming-payment',
    id: 'INCOMING_PAYMENT',
    icon: 'inwardPayment',
  },
  INWARD_HISTORY: {
    label: 'Inward History',
    url: 'inward-history',
    id: 'INWARD_HISTORY',
    icon: 'inwardHistory',
  },
  DELIVERED_PACKAGES: {
    label: 'Delivered Packages',
    url: 'delivered-packages',
    id: 'DELIVERED_PACKAGES',
    icon: 'deliveredPackages',
  },
  RETURN_HISTORY: {
    label: 'Return History',
    url: 'return-history',
    id: 'RETURN_HISTORY',
    icon: 'returnsHistory',
  },
  PAYMENTS: {
    label: 'Payments',
    url: 'payments',
    id: 'PAYMENTS',
    icon: 'payments',
  },
  BOX_SCAN: {
    label: 'Box Scan',
    url: 'box-scan',
    id: 'BOX_SCAN',
    icon: 'boxScan',
  },
  RECEIVE_INTERSTATE_PACKAGES: {
    label: 'Receive Interstate Consignments',
    url: 'receive-interstate-consignments',
    id: 'RECEIVE_INTERSTATE_PACKAGES',
    icon: 'InterstatePackage',
  },
  RETURN_CONSIGNMENT_CHALLAN: {
    label: 'Return Consignment Challan',
    url: 'return-cosignment-challan',
    id: 'RETURN_CONSIGNMENT_CHALLAN',
    icon: 'returnRequest',
  },
  PICK_TO_BE_SCHEDULED: {
    label: 'Pick Up To Be Scheduled',
    url: 'pick-up-to-be-scheduled',
    id: 'PICK_TO_BE_SCHEDULED',
    icon: 'PickUpSchedule',
  },
  SAATHI_RETURN_PACKAGES: {
    label: 'Saathi Return Packages',
    url: 'saathi-return-packages',
    id: 'SAATHI_RETURN_PACKAGES',
    icon: 'SaathiReturns',
  },
  DELIVERY_VIA_STORE: {
    label: 'Store Orders',
    url: 'delivery-via-saathi-store',
    id: 'DELIVERY_VIA_STORE',
    icon: 'DeliveryViaStore',
  },
};

export const LEGACY_LINKS = {
  [TABS.ASSIGNED_PACKAGES.id]: '#!/ops/assigned-packages',
  [TABS.RETURN_REQUESTS.id]: '#!/ops/returns/partners-returns',
  [TABS.INCOMING_PAYMENT.id]: '#!/accounts/incoming-payments',
  [TABS.OUTGOING_PAYMENT.id]: '#!/accounts/outgoing-payments',
  [TABS.INWARD_HISTORY.id]: '#!/inward-consignments/consignments-history',
  [TABS.DELIVERED_PACKAGES.id]: '#!/packages/packages-delivered',
  [TABS.RETURN_HISTORY.id]: '#!/return/history',
  [TABS.PAYMENTS.id]: '#!/payment/payables/payables-list',
};

export const SUB_TABS = {
  [TABS.OUTWARD_CONSIGNMENTS.id]: [
    {
      id: 'PENDING_PACKAGES',
      label: 'Pending Packages',
      url: 'pending-packages',
    },
    {
      id: 'CONFIRM_CONSIGNMENTS',
      label: 'Confirm Consignment',
      url: 'confirm-consignments',
    },
    {
      id: 'TO_BE_DISPATCHED',
      label: 'To-Be Dispatched',
      url: 'to-be-dispatched',
    },
    {
      id: 'DISPATCHED_CONSIGNMENT',
      label: 'Dispatched Consignments',
      url: 'dispatched-consignment',
    },
  ],
  [TABS.MISSING.id]: [
    {
      id: 'INWARD_MISSING',
      label: 'Inward Missing',
      url: 'inward-missing',
    },
    {
      id: 'RETURN_MISSING',
      label: 'Return Missing',
      url: 'return-missing',
    },
  ],
  [TABS.REVERSE_LOGISTICS.id]: [
    { id: 'RETURN_FROM_LP', label: 'Return From LP', url: 'return-from-lp' },
    {
      id: 'RETURN_TO_FC',
      label: 'Return Giving to other FC',
      url: 'return-to-fc',
    },
    {
      id: 'RETURN_FROM_FC',
      label: 'Return Coming from other FC',
      url: 'return-from-fc',
    },
  ],
  [TABS.DISPATCHED_TO_LP.id]: [
    {
      id: 'TO_BE_DISPATCH_B2B',
      label: 'To be Dispatched B2B',
      url: 'to-be-dispatched-b2b',
    },
    {
      id: 'TO_BE_DISPATCH_B2C',
      label: 'To be Dispatched B2C',
      url: 'to-be-dispatched-b2c',
    },
    // {
    //   id: 'TO_BE_DISPATCH_LOCUS',
    //   label: 'To be Dispatched Locus',
    //   url: 'to-be-dispatched-locus',
    // },
    {
      id: 'PENDING_LR',
      label: 'Pending LR',
      url: 'pending-lr',
    },
    {
      id: 'DISPATCHED_HISTORY',
      label: 'Dispatched History',
      url: 'dispatched-history',
    },
  ],
  [TABS.INTER_FACILITY_DISPATCH.id]: [
    {
      id: 'TO_BE_DISPATCH',
      label: 'To be Dispatched',
      url: 'to-be-dispatched',
    },
    {
      id: 'PENDING_LR',
      label: 'Pending LR',
      url: 'pending-lr',
    },
    {
      id: 'DISPATCHED_HISTORY',
      label: 'Dispatched History',
      url: 'dispatched-history',
    },
  ],
};

export const DRILLDOWN_GROUPS_CONST = {
  REVERSE_LOGISTIC: 'REVERSE_LOGISTIC',
  FORWARD_LOGISTIC: 'FORWARD_LOGISTIC',
  BALANCE_PAYABLE: 'BALANCE_PAYABLE',
  SKU: 'SKU',
};

export const DRILLDOWN_GROUPS = {
  [DRILLDOWN_GROUPS_CONST.REVERSE_LOGISTIC]: {
    id: 'REVERSE_LOGISTIC',
    label: 'Reverse Logistics',
    url: 'reverse-logistic',
  },
  [DRILLDOWN_GROUPS_CONST.FORWARD_LOGISTIC]: {
    id: 'FORWARD_LOGISTIC',
    label: 'Forward Logistics',
    url: 'forward-logistic',
  },
  [DRILLDOWN_GROUPS_CONST.BALANCE_PAYABLE]: {
    id: 'BALANCE_PAYABLE',
    label: 'Balance Payable',
    url: 'balance-payable',
  },
  [DRILLDOWN_GROUPS_CONST.SKU]: {
    id: 'SKU',
    label: 'SKU',
    url: 'sku',
  },
};

export const WIDGET_GROUPS = {
  PACKAGES: 'Packages',
  CHARTS_GRAPHS: 'Charts & Graphs',
  PAYMENTS: 'Payments',
};

// by default all widgets will be shown
// Facility can be merge at API level
export const Widgets = {
  DELIVERED: {
    name: 'delivered',
    isUpdateEndDate: true,
    groupName: WIDGET_GROUPS.PACKAGES,
    props: {
      size: 'small',
      title: 'Delivered',
      subTitle: 'today',
      downloadable: true,
    },
    request: {
      url: 'packageaggregation',
      body: {
        filters: {
          ...getTodayDateTimeStamp(),
          status: ShipPkgDeliveryStatusCNST.DELIVERED,
        },
        groups: ['lpType'],
        sendData: false,
      },
    },
  },
  DISPATCHED: {
    name: 'dispatched',
    isUpdateEndDate: true,
    groupName: WIDGET_GROUPS.PACKAGES,
    props: {
      size: 'small',
      title: 'Dispatched',
      subTitle: 'today',
      downloadable: true,
    },
    request: {
      url: 'packageaggregation',
      body: {
        filters: {
          startDate: new Date().setHours(0, 0, 0, 0),
          endDate: new Date().getTime(),
          historyStatus: ShipPkgDeliveryStatusCNST.DISPATCHED,
        },
        groups: ['lpType'],
        sendData: false,
      },
    },
  },
  TO_DELIVER: {
    name: 'to deliver',
    groupName: WIDGET_GROUPS.PACKAGES,
    props: {
      size: 'small',
      title: 'To Deliver',
      subTitle: 'till date',
      downloadable: true,
      drillDown: { path: '/app/drilldown/forward-logistic/to-deliver' },
    },
    request: {
      url: 'packageaggregation',
      body: {
        filters: {
          status: ShipPkgDeliveryStatusCNST.TO_DELIVER,
        },
        groups: ['lpType'],
        sendData: false,
      },
    },
  },
  PENDING_RETURN_REQUEST: {
    name: 'pending return request',
    groupName: WIDGET_GROUPS.PACKAGES,
    props: {
      size: 'small',
      title: 'Pending Return Request',
      subTitle: 'till date',
      downloadable: true,
    },
    request: {
      url: 'packageaggregation',
      body: {
        filters: {
          status: ShipPkgDeliveryStatusCNST.RETURN_REQUESTED,
        },
        sendData: false,
      },
    },
  },
  IN_TRANSIT: {
    name: 'in transit',
    groupName: WIDGET_GROUPS.PACKAGES,
    props: {
      size: 'small',
      title: 'In Transit',
      subTitle: 'till date',
      downloadable: true,
      drillDown: { path: '/app/drilldown/forward-logistic/in-transit' },
    },
    request: {
      url: 'packageaggregation',
      body: {
        filters: {
          status: ShipPkgDeliveryStatusCNST.DISPATCHED,
        },
        groups: ['lpType'],
        sendData: false,
      },
    },
  },
  ON_HOLD: {
    name: 'on hold',
    groupName: WIDGET_GROUPS.PACKAGES,
    props: {
      size: 'small',
      title: 'On Hold',
      subTitle: 'till date',
      downloadable: true,
    },
    request: {
      url: 'packageaggregation',
      body: {
        filters: {
          status: ShipPkgDeliveryStatusCNST.HOLD,
        },
        groups: ['lpType'],
        sendData: false,
      },
    },
  },
  BUSINESS_COVERAGE: {
    name: 'business coverage',
    isUpdateEndDate: true,
    groupName: WIDGET_GROUPS.CHARTS_GRAPHS,
    props: {
      size: 'large',
      title: 'Business Coverage',
      subTitle: 'today',
    },
    request: {
      url: 'coverage',
      body: {
        filters: {
          startDate: businessCoverageDateRange.startDate.valueOf(),
          endDate: businessCoverageDateRange.endDate.valueOf(),
        },
        groups: ['lpType'],
        sendData: false,
      },
    },
  },
  DELIVERY_TAT: {
    name: 'delivery tat',
    groupName: WIDGET_GROUPS.CHARTS_GRAPHS,
    props: {
      size: 'large',
      title: 'Delivery TAT',
    },
    request: {
      url: 'tat',
      body: {
        filters: {
          startDate: deliveryTatDateRange.startDate.valueOf(),
          endDate: deliveryTatDateRange.endDate.valueOf(),
        },
        bucketLength: 0,
        lpType: '',
        tatType: 'deliveryDispatch',
      },
    },
  },
  MONEY_COLLECTION_TAT: {
    name: 'money collection tat',
    groupName: WIDGET_GROUPS.CHARTS_GRAPHS,
    props: {
      size: 'large',
      title: 'Money Collection Tat',
    },
    request: {
      url: 'tat',
      body: {
        filters: {
          startDate: deliveryTatDateRange.startDate.valueOf(),
          endDate: deliveryTatDateRange.endDate.valueOf(),
        },
        bucketLength: 3,
        lpType: '',
        tatType: 'collectionDispatch',
      },
    },
  },
  ORDER_PAID_BY: {
    name: 'orders paid by',
    groupName: WIDGET_GROUPS.PAYMENTS,
    props: {
      size: 'medium',
      title: 'Orders Paid By',
      subTitle: 'this month',
    },
    request: {
      url: 'packageaggregation',
      body: {
        sendData: false,
        filters: {
          startDate: getDateTimeStamp(getThisMonthRange().startDate),
          endDate: getDateTimeStamp(getThisMonthRange().endDate),
          status: ShipPkgDeliveryStatusCNST.DELIVERED,
          reconciliationStatus: ShippingPackageStatusCNST.RECONCILED,
        },
        groups: ['paymentMode', 'lpType'],
      },
    },
  },
  RETURNED: {
    name: 'returned',
    groupName: WIDGET_GROUPS.PACKAGES,
    props: {
      size: 'small',
      title: 'Returns',
      subTitle: 'till date',
    },
    request: {
      url: 'returnaggregation',
      body: {
        sendData: false,
        filters: {
          startDate: getDateTimeStamp(getThisMonthRange().startDate),
          endDate: getDateTimeStamp(getThisMonthRange().endDate),
          historyStatus: ShipPkgDeliveryStatusCNST.RETURNED,
        },
        groups: ['lpType'],
      },
    },
  },
  PROCESS_FOR_RETURNS: {
    name: 'returned',
    groupName: WIDGET_GROUPS.PACKAGES,
    props: {
      size: 'small',
      title: 'Process for Returns',
      subTitle: 'till date',
      drillDown: { path: '/app/drilldown/reverse-logistic/process-for-return' },
    },
    request: {
      url: 'packageaggregation',
      body: {
        filters: {
          status: ShipPkgDeliveryStatusCNST.RETURNED,
        },
        groups: ['lpType'],
        sendData: false,
      },
    },
  },
  RETURN_MANIFEST_CREATED: {
    name: 'returned',
    groupName: WIDGET_GROUPS.PACKAGES,
    props: {
      size: 'small',
      title: 'Return Manifest Created',
      subTitle: 'till date',
      drillDown: {
        path: '/app/drilldown/reverse-logistic/return-manifest-created',
      },
    },
    request: {
      url: 'packageaggregation',
      body: {
        filters: {
          status: ShipPkgDeliveryStatusCNST.RETURN_MANIFEST_CREATED,
        },
        groups: ['lpType'],
        sendData: false,
      },
    },
  },
  RETURN_IN_TRANSIT: {
    name: 'returned',
    groupName: WIDGET_GROUPS.PACKAGES,
    props: {
      size: 'small',
      title: 'Return In Transit',
      subTitle: 'till date',
      drillDown: { path: '/app/drilldown/reverse-logistic/return-in-transit' },
    },
    request: {
      url: 'packageaggregation',
      body: {
        filters: {
          status: ShipPkgDeliveryStatusCNST.RETURN_IN_TRANSIT,
        },
        groups: ['lpType'],
        sendData: false,
      },
    },
  },
  TO_BE_VERIFIED_PAYABLES: {
    name: 'to be verified payables',
    groupName: WIDGET_GROUPS.PAYMENTS,
    props: {
      size: 'small',
      title: 'To Be Verified Payables',
      subTitle: 'till date',
      downloadable: true,
    },
    request: {
      url: 'paymentaggregation',
      body: {
        sendData: false,
        filters: {
          startDate: getDateTimeStamp(getThisMonthRange().startDate),
          endDate: getDateTimeStamp(getThisMonthRange().endDate),
          status: 'pending',
        },
        groups: ['lpType'],
        aggregationType: 'sum',
        aggregationKey: 'claimedAmount',
      },
    },
  },
  BALANCE_PAYABLE: {
    name: 'Balance Payable',
    groupName: WIDGET_GROUPS.PAYMENTS,
    props: {
      size: 'small',
      title: 'Balance Payable',
      subTitle: 'till date',
      downloadable: true,
      drillDown: { path: '/app/drilldown/balance-payable' },
    },
    request: {
      url: 'payablesummary',
      body: {
        sendData: false,
        filters: {},
        groups: ['lpType'],
      },
    },
  },
  USER_ACTIVITY: {
    name: 'user activity',
    groupName: WIDGET_GROUPS.CHARTS_GRAPHS,
    props: {
      size: 'large',
      title: 'User Activity',
      subTitle: 'today',
    },
    request: {
      url: 'activity',
      body: {
        filters: {},
      },
    },
  },
  INWARD_MISSING: {
    name: 'Inward Missing',
    groupName: WIDGET_GROUPS.PACKAGES,
    props: {
      size: 'small',
      title: 'Inward Missing',
      subTitle: 'till date',
      drillDown: { path: '/app/missing/inward-missing', state: 'missing' },
    },
    request: {
      url: 'missingintransit',
      body: {
        filters: {},
      },
    },
  },
  ASSIGNED: {
    name: 'Assigned',
    groupName: WIDGET_GROUPS.PACKAGES,
    props: {
      size: 'small',
      title: 'Assigned',
      subTitle: 'till date',
      downloadable: true,
    },
    request: {
      url: 'packageaggregation',
      body: {
        sendData: false,
        filters: {
          packageState: ShippingPackageStatusCNST.ASSIGNED,
        },
        groups: ['lpType'],
      },
    },
  },
  RETURN_MISSING: {
    name: 'Return Missing',
    groupName: WIDGET_GROUPS.PACKAGES,
    props: {
      size: 'small',
      title: 'Return Missing',
      subTitle: 'till date',
      drillDown: { path: '/app/missing/return-missing', state: 'missing' },
    },
    request: {
      url: 'missinginreturn',
      body: {
        filters: {},
      },
    },
  },
  SKU: {
    name: 'SKU',
    groupName: WIDGET_GROUPS.PACKAGES,
    props: {
      size: 'small',
      title: 'Returning SKUs',
      subTitle: 'till date',
      downloadable: true,
      drillDown: { path: '/app/drilldown/sku' },
    },
    request: {
      url: 'skuwidget',
      body: {
        filters: {},
        sendData: false,
      },
    },
  },
};

export const PERMISSIONS = {
  MISSING_TICKET_UPDATE_STATUS: 'MissingTicketUpdateStatus',
  PACKAGE_STATUS_UNDO: 'PackageStatusUndo',
  PACKAGE_ARCHIVE: 'PackageArchive',
};
