import { removeUser } from 'Utilities/localStorage';
import * as types from './types';

export const loginUser = user => dispatch => {
  return dispatch({
    type: types.USERS_LOGIN,
    user,
  });
};

export const logoutUser = () => dispatch => {
  removeUser();

  return dispatch({
    type: types.USERS_LOGOUT,
  });
};

export const setUserState = state => dispatch => {
  return dispatch({
    type: types.SET_USER_STATE,
    state,
  });
};
