import styled, { css } from 'styled-components';

import { Typography } from '../../Typography';
import { DIRECTIONS, DIRECTIONS_MARGIN } from './constants';

export const Wrapper = styled(Typography)`
  display: inline-flex;
  align-items: center;
  ${props => `flex-direction: ${DIRECTIONS[props.labelTextPlacement]};`}
  margin: ${props => props.theme.dsSpacing(1)};
  ${props =>
    props.disabled &&
    css`
      opacity: ${props.theme.config.disabledOpacity};
    `}
`;

export const LabelWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  ${props =>
    `margin-${
      DIRECTIONS_MARGIN[props.labelTextPlacement]
    }: ${props.theme.dsSpacing(2)};`}
`;
