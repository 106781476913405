import { createSelector } from 'reselect';

const totalConsignmentSelector = (_, list) => {
  return list.reduce((total, a) => total + a.openConsignments, 0);
};

const totalPackagesSelector = (_, list) => {
  return list.reduce((total, a) => total + a.openPackages, 0);
};

export const totalToPaySelector = (_, list) => {
  return list.reduce((total, a) => total + (a.toPay || 0), 0);
};

const totalPackageAmountSelector = (_, list) => {
  return list.reduce((total, a) => total + a.totalValue, 0);
};

export const getTotalCount = createSelector(
  [
    totalConsignmentSelector,
    totalPackagesSelector,
    totalPackageAmountSelector,
    totalToPaySelector,
  ],
  (totalConsignment, totalPackages, totalPackageAmount, totalToPay) => {
    return {
      totalAssignment: totalConsignment,
      totalPackages,
      totalToPay,
      totalPackageValue: totalPackageAmount,
    };
  },
);
