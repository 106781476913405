import styled, { keyframes } from 'styled-components';
import { fade } from 'Components/Utils';

const positionCss = {
  left: {
    enter: 'translate3d(-100%, 0, 0)',
    exit: 'translate3d(0, 0, 0)',
    position: {
      top: 0,
      left: 0,
    },
  },
  right: {
    enter: 'translate3d(100%, 0, 0)',
    exit: 'translate3d(0, 0, 0)',
    position: {
      top: 0,
      right: 0,
    },
  },
};

const slide = props => keyframes`

  0% {
    transform: ${positionCss[props.position].enter};
  }

  100% {
    transform: ${positionCss[props.position].exit};
  }
`;

const fadeIn = keyframes`

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const BodyWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: ${props => props.theme.config.zIndex.drawer};
  display: ${props => (props.visible ? 'inline-block' : 'none')};
  width: 100%;
  height: 100%;
  animation: ${fadeIn} 0.2s;

  &.model-body-animation-exit {
    opacity: 1;
  }

  &.model-body-animation-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
`;

export const DrawerWrapper = styled.div`
  position: absolute;
  display: inline-block;
  ${props => positionCss[props.position].position}
  height: 100%;
  background-color: ${props => props.theme.palette.colors.white};
  animation: ${slide} 0.5s;

  &.drawer-animation-exit {
    transform: ${props => positionCss[props.position].exit};
  }

  &.drawer-animation-exit-active {
    transform: ${props => positionCss[props.position].enter};
    transition: all 0.5s;
  }

  &.drawer-animation-exit-done {
    transform: ${props => positionCss[props.position].exit};
  }
`;
