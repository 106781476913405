import {interstateFranchiseList} from 'Services/consignment';
import _sortBy from 'lodash/sortBy';
import * as types from './types';

export const getManifestList = () => dispatch => {
  return interstateFranchiseList().then(
    function(response) {
      return dispatch({
        type: types.GET_INTERSTATE_FRANCHISE_SUCCESS,
        payload: _sortBy(response.facilities || [], 'name'),
      });
    },
    function({ response: { data } }) {
      return dispatch({
        type: types.GET_INTERSTATE_FRANCHISE_FAILURE,
        payload: data.message,
      });
    },
  );
};


export const setSelectManifest = data => dispatch => {
  return dispatch({
    type: types.SET_SELECTED_FRANCHISE,
    payload: data,
  });
};
