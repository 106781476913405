import React from 'react';

const LoginPage = React.lazy(() => import('./LoginPage'));
const Dashboard = React.lazy(() => import('./Dashboard'));
const Drilldown = React.lazy(() => import('./DashboardDrilldown'));
const Returns = React.lazy(() => import('./Returns'));
const MissingPackages = React.lazy(() => import('./Missing/MissingPackages'));
const Missing = React.lazy(() => import('./Missing'));
const LPDispatch = React.lazy(() => import('./Dispatch/LPDispatch'));
const InterFCDispatch = React.lazy(() => import('./Dispatch/InterFCDispatch'));
const Reports = React.lazy(() => import('./Reports'));
const OutwardConsignment = React.lazy(() => import('./OutwardConsignment'));
const PartnerAccounts = React.lazy(() => import('./PartnerAccounts'));
const OutgoingPayment = React.lazy(() => import('./OutgoingPayment'));
const IncomingPayment = React.lazy(() => import('./IncomingPayment'));
const Search = React.lazy(() => import('./Search'));
const PartnersProfile = React.lazy(() => import('./PartnersProfile'));
const Transports = React.lazy(() => import('./Transports'));
const AssignedPackages = React.lazy(() => import('./AssignedPackages'));
const PendingPackageAssignment = React.lazy(() =>
  import('./PendingPackageAssignment'),
);
const BoxScan = React.lazy(() => import('./BoxScan'));
const ForwardSaathiPackages = React.lazy(() => import('./SaathiPackages/ForwardSaathiPackages'));
const ReceiveInterstatePackages = React.lazy(()=>import('./ReceiveInterstatePackages'));
const ReturnCosignmentChallan = React.lazy(()=>import('./ReturnCosignmentChallan'));
const PickUpToBeScheduled = React.lazy(()=>import('./PickUpToBeScheduled'));
const SaathiReturnPackages = React.lazy(()=> import('./SaathiPackages/SaathiReturnPackages'));
const DeliveryViaStore = React.lazy(()=>import('./DeliveryViaStore'));

export default {
  LOGIN: LoginPage,
  DASHBOARD: Dashboard,
  DRILLDOWN: Drilldown,
  REVERSE_LOGISTICS: Returns,
  MISSING_PACKAGES: MissingPackages,
  MISSING: Missing,
  DISPATCHED_TO_LP: LPDispatch,
  INTER_FACILITY_DISPATCH: InterFCDispatch,
  REPORTS: Reports,
  OUTWARD_CONSIGNMENTS: OutwardConsignment,
  PARTNERS_ACCOUNTS: PartnerAccounts,
  SEARCH: Search,
  OUTGOING_PAYMENT: OutgoingPayment,
  INCOMING_PAYMENT: IncomingPayment,
  PARTNERS_PROFILE: PartnersProfile,
  TRANSPORTS: Transports,
  ASSIGNED_PACKAGES: AssignedPackages,
  PENDING_ASSIGNMENT: PendingPackageAssignment,
  BOX_SCAN: BoxScan,
  SAATHI_PACKAGES: ForwardSaathiPackages,
  RECEIVE_INTERSTATE_PACKAGES : ReceiveInterstatePackages,
  RETURN_CONSIGNMENT_CHALLAN : ReturnCosignmentChallan,
  PICK_TO_BE_SCHEDULED : PickUpToBeScheduled,
  SAATHI_RETURN_PACKAGES : SaathiReturnPackages,
  DELIVERY_VIA_STORE: DeliveryViaStore
};
