import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Arrow } from 'Components/Icons';

import {
  Wrapper,
  HeaderPanel,
  BodyWrapper,
  ArrowAction,
  Body,
  Footer,
} from './styled';

const Accordion = React.memo(
  ({ header, isOpen, onToggleVisible, children }) => {
    const [isAccordionOpen, setAccordionOpen] = useState(false);

    useEffect(() => {
      setAccordionOpen(isOpen);
    }, [isOpen]);

    const onHeaderClick = () => {
      if (onToggleVisible) {
        onToggleVisible(!isAccordionOpen);
      } else {
        setAccordionOpen(!isAccordionOpen);
      }
    };

    return (
      <Wrapper data-testid="accordion">
        <HeaderPanel
          isOpen={isAccordionOpen}
          onClick={onHeaderClick}
          data-testid="accordion-header"
        >
          {header}
          <ArrowAction data-testid="accordion-action-icon">
            <Arrow direction={isAccordionOpen ? 'up' : 'down'} />
          </ArrowAction>
        </HeaderPanel>
        <BodyWrapper isOpen={isAccordionOpen} data-testid="accordion-body">
          {children}
        </BodyWrapper>
      </Wrapper>
    );
  },
);

Accordion.defaultProps = {
  isOpen: false,
  onToggleVisible: false,
};

Accordion.propTypes = {
  isOpen: PropTypes.bool,
  onToggleVisible: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

Accordion.Body = Body;
Accordion.Footer = Footer;

export default Accordion;
