import styled, { css } from 'styled-components';
import { lighten } from 'Components/Utils';
import { Loader } from '../Loader';

export const Table = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  ${props =>
    props.bordered &&
    css`
      border: 1px solid ${props.theme.palette.borderColors.dark};
    `}
`;

export const TableRow = styled.div`
  display: inline-flex;
  border-bottom: 1px solid ${props => props.theme.palette.borderColors.dark};
`;

export const TableCell = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.cellPadding};
  ${props =>
    props.cellWidth
      ? css`
          width: ${props.cellWidth};
        `
      : css`
          flex: 1;
        `}
  ${props =>
    (props.sort || props.onClick) &&
    css`
      cursor: pointer;
    `}

  .sortIcon {
    margin-left: ${props => props.theme.dsSpacing(1)};
  }

  &.selectCheckbox {
    justify-content: center;
    width: 40px;
    font-size: 1.1em;
  }
`;

export const TableHeader = styled(TableRow)`
  z-index: 1;
  font-weight: bold;
  box-shadow: ${props => props.theme.config.shadows[0]};
`;

export const TableFooter = styled(TableRow)`
  z-index: 1;
  border: none;
  box-shadow: ${props => props.theme.config.shadows[1]};
`;

export const TableBody = styled.div`
  display: inline-flex;
  flex: 1;
  flex-direction: column;
  background: ${props => props.theme.palette.colors.white};
  ${props =>
    props.sticky &&
    css`
      overflow: auto;
    `}
  ${props =>
    props.striped &&
    css`
      > div:nth-of-type(odd),
      .virtualContainer > div > div:nth-of-type(odd) {
        background: ${lighten(props.theme.palette.borderColors.dark, 0.9)};
      }
    `}
  ${props =>
    props.hover &&
    css`
      > div:hover,
      .virtualContainer > div > div:hover {
        background: ${lighten(props.theme.palette.borderColors.dark, 0.4)};
      }
    `}
`;

export const RowLoader = styled(Loader)`
  margin: ${props => props.theme.dsSpacing(8, 0)};
`;
