import * as types from './types';

const initialState = {};

const widget = (state = initialState, action) => {
  switch (action.type) {
    case types.WIDGET_LOAD_ALL:
      return action.widgets;
    case types.WIDGET_UPDATE_FILTERS:
      const updatedData = { ...state[action.widgetId] };
      updatedData.requestBody = action.body;
      return { ...state, [action.widgetId]: updatedData };
    default:
      return state;
  }
};

export default {
  widgets: widget,
};
