import styled, { css } from 'styled-components';

export const Wrapper = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  ${props => props.disabled && css`
    opacity: ${props.theme.config.disabledOpacity};
  `}
`;

export const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: 0;
`;
