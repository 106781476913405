import React, { useState } from 'react';

import Header from './components/Header';
import Sidebar from './components/Sidebar';

import { SiteWrapper, AppWrapper, MainContentWrapper } from './styled';

const SiteLayout = ({ children }) => {
  return <SiteWrapper>{children}</SiteWrapper>;
};

export const AppLayout = ({ children, history, match }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <>
      <Header history={history} toggleSidebar={toggleSidebar} />
      <AppWrapper>
        <Sidebar
          match={match}
          sidebarOpen={sidebarOpen}
          toggleSidebar={toggleSidebar}
        />
        <MainContentWrapper>{children}</MainContentWrapper>
      </AppWrapper>
    </>
  );
};

export default SiteLayout;
