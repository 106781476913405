/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { lighten } from 'Components/Utils';

// TODO: use border color from theme
export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60px;
  height: 100%;
  background: ${props => props.theme.palette.colors.white};
  border-right: 1px solid #eaeaea;
`;

export const Link = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${props => props.theme.spacing(1, 0)};
  padding: ${props => props.theme.spacing(1, 2)};
  color: inherit;
  text-align: center;
  text-decoration: none;

  ${props =>
    props.showlabel &&
    css`
      justify-content: start;

      svg {
        margin-right: ${props.theme.spacing(1)};
      }
    `}

  ${props =>
    props.hassubmenu &&
    css`
      margin-bottom: 0;
    `}

  &:first-child {
    margin-top: ${props => props.theme.spacing(2)};
  }

  &.active,
  &:hover {
    color: ${props => props.theme.palette.colors.primary};
    background-color: ${props =>
      lighten(props.theme.palette.colors.primary, 0.9)};
  }

  &.active {
    &::before {
      position: absolute;
      left: 0;
      display: block;
      width: 5px;
      height: 100%;
      background: ${props => props.theme.palette.colors.primary};
      border-right: 1px white;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      content: '';
    }
  }
`;

export const ExternalLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  text-align: center;
  text-decoration: none;
  margin: ${props => props.theme.spacing(1, 0)};
  padding: ${props => props.theme.spacing(1, 2)};

  ${props =>
    props.showlabel &&
    css`
      justify-content: start;

      svg {
        margin-right: ${props => props.theme.spacing(1)};
      }
    `}

  ${props =>
    props.hassubmenu &&
    css`
      margin-bottom: 0;
    `}

  &:first-child {
    margin-top: ${props => props.theme.spacing(2)};
  }

  &.active,
  &:hover {
    color: ${props => props.theme.palette.colors.primary};
    background-color: ${props =>
      lighten(props.theme.palette.colors.primary, 0.9)};
  }

  &.active {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      height: 100%;
      display: block;
      width: 5px;
      border-right: 1px white;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      background: ${props => props.theme.palette.colors.primary};
    }
  }
`;

export const SubMenuLink = styled(NavLink)`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  margin: ${props => props.theme.spacing(1, 0)};
  padding: ${props => props.theme.spacing(1, 3)};

  svg {
    margin-right: ${props => props.theme.spacing(1)};
  }

  &.active,
  &:hover {
    color: ${props => props.theme.palette.colors.primary};
  }
`;

export const Separator = styled.hr`
  margin: ${props => props.theme.spacing(2, 0)};
`;

// TODO: use color from theme
export const SubTabWrapper = styled.div`
  background: #fafafa;
  padding: ${props => props.theme.spacing(1, 0)};
`;

export const MenuIcon = styled.span``;

export const SidebarItemWrapper = styled.div``;
