import styled, { css } from 'styled-components';

export const DropDownWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: inherit;
`;

export const DropDownInput = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.dsSpacing(2.5)};
  border: 1px solid ${props => props.theme.palette.borderColors.default};
  border-radius: ${props => props.theme.config.borderRadius};
  outline: none;
  cursor: pointer;

  p {
    padding-right: ${props => props.theme.dsSpacing(5)};
  }

  ${props =>
    props.block &&
    css`
      width: 100%;
    `}
  ${props =>
    props.status &&
    css`
      border-color: ${props.theme.palette.colors[props.status]};
    `}

  &:focus {
    border-color: ${props => props.theme.palette.colors.info};
  }

  ${props =>
    props.error &&
    css`
      border-color: ${props.theme.palette.colors.error};
    `}

  .arrow-icon {
    margin: ${props => props.theme.dsSpacing(0, 2)};
  }

  ${props =>
    props.disabled &&
    css`
      opacity: ${props.theme.config.disabledOpacity};
    `}
`;

export const DropDownArea = styled.div`
  position: absolute;
  z-index: ${props => props.theme.config.zIndex.dropdown};
  width: inherit;
  box-shadow: ${props => props.theme.config.shadows[3]};
  ${props => props.position === 'left' && css`
    left: 0;
  `}
  ${props => props.position === 'right' && css`
    right: 0;
  `}
  ${props =>
    !props.isOpen &&
    css`
      display: none;
    `}
`;
