import React from 'react';
import { Icon } from 'Components/Icons';

const PickUpSchedule = props => (
  <Icon viewBox="0 0 45 50" {...props}>
    <path d="m39.4 16.65 1.15-1.1-3.7-3.75V5.9h-1.6v6.35Zm-3.45 4q-3.55 0-6.075-2.525Q27.35 15.6 27.35 12t2.525-6.1Q32.4 3.4 36 3.4t6.1 2.525q2.5 2.525 2.5 6.125 0 3.55-2.525 6.075-2.525 2.525-6.125 2.525ZM12.5 33.9q1 0 1.7-.7t.7-1.75q0-1-.7-1.7t-1.7-.7q-1 0-1.725.725-.725.725-.725 1.725t.725 1.7q.725.7 1.725.7Zm15 0q1 0 1.725-.7.725-.7.725-1.75 0-1-.725-1.7t-1.725-.7q-1 0-1.7.725t-.7 1.725q0 1 .7 1.7t1.7.7ZM9.35 43q-.45 0-.825-.275Q8.15 42.45 8.15 42v-4.05q-1.35-.6-2.25-2.1-.9-1.5-.9-3.25V13.35q0-1.85 1.125-3.175 1.125-1.325 3.5-2.1Q12 7.3 15.75 7.1q3.75-.2 8.95.05-.75 1.8-.925 3.7-.175 1.9.275 3.8H7.25v9.4H32.9q.35 0 1.05.1.7.1 1.05.1v8.35q0 1.75-.9 3.25t-2.25 2.1V42q0 .45-.375.725Q31.1 43 30.65 43h-.45q-.6 0-.95-.275T28.9 42v-3.1H11.1V42q0 .45-.35.725Q10.4 43 9.8 43Z" />
  </Icon>
);

export default PickUpSchedule;
