import React from 'react';

import { Icon } from 'Components/Icons';

const ToggleIcon = props => (
  <Icon viewBox="0 0 20 16" {...props}>
    <rect width="20" height="2.4" fill="#999999" rx="1.2" />
    <path
      fill="#999999"
      d="M0 7.6a1.2 1.2 0 0 1 1.2-1.2h12a1.2 1.2 0 0 1 0 2.4h-12A1.2 1.2 0 0 1 0 7.6z"
    />
    <rect width="20" height="2.4" y="13" fill="#999999" rx="1.2" />
  </Icon>
);

export default ToggleIcon;
