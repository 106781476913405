import { lmdService, lmdV3Service } from 'Services/base';
import { urlParams } from 'Utilities';

const API_URL = '/franchise';
export const franchiseCancelToken = {};

export const getFranchiseGroupList = state => {
  return lmdV3Service.get('franchisegroup/', { headers: { state } });
};

export const getFranchiseListPartnerList = (params) => {
  franchiseCancelToken.getFranchiseList = lmdService.CancelToken.source();
  return lmdService.get('franchise_v1', {
    params,
    cancelToken: franchiseCancelToken.getFranchiseList.token,
  });
};

export const getFranchiseList = (fields = []) => {
  franchiseCancelToken.getFranchiseList = lmdService.CancelToken.source();
  const params = urlParams({
    fields,
  });
  return lmdService.get(API_URL, {
    params,
    cancelToken: franchiseCancelToken.getFranchiseList.token,
  });
};

export const getFranchiseDetails = id => {
  return lmdService.get(`${API_URL}/${id}`);
};

export const createFranchise = franchise => {
  return lmdService.post(API_URL, franchise);
};

export const updateFranchise = (franchise, franchiseId) => {
  return lmdService.put(`${API_URL}/${franchiseId}`, franchise);
};

export const getFranchisePickupLocations = id => {
  return lmdService.get(`/pickuplocation/?franchiseId=${id}`);
};

export const getFacilityPickupLocations = (
  id,
  dispatchFacility,
  interstate,
) => {
  const params = urlParams({
    dispatchFacility,
    interstate,
  });
  return lmdService.get(`/pickuplocation/?franchiseId=${id}`, {
    params,
  });
};

export const addNewPickupLocation = pickupLocationDetail => {
  return lmdService.post(`/pickuplocation/`, pickupLocationDetail);
};

export const updatePickupLocation = (
  pickupLocationDetail,
  pickupLocationDetailId,
) => {
  return lmdService.put(
    `/pickuplocation/${pickupLocationDetailId}/`,
    pickupLocationDetail,
  );
};

export const getLocation = params => {
  return lmdV3Service.get('location/', { params });
};

export const addDeliveryAreas = pickupLocationDetail => {
  return lmdService.post(`/deliveryArea/`, pickupLocationDetail);
};

export const editDeliveryAreas = (id, pickupLocationDetail) => {
  return lmdService.put(`/deliveryArea/${id}`, pickupLocationDetail);
};
