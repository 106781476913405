import styled from 'styled-components';

// TODO: use border color from theme
export const AppDrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 250px;
  background: ${props => props.theme.palette.colors.white};
  border-right: 1px solid #eaeaea;
`;

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.spacing(2, 0, 2, 2.5)};
`;

export const ToggleWrapper = styled.div`
  width: 60px;
  text-align: center;
  cursor: pointer;
`;

export const MenuWrapper = styled.div`
  flex: 1;
  overflow: auto;
`;
