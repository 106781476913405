export const PLACEMENT = {
  TOP: 'top',
  LEFT: 'left',
  RIGHT: 'right',
  BOTTOM: 'bottom',
};

export const DIRECTIONS = {
  [PLACEMENT.TOP]: 'column-reverse',
  [PLACEMENT.LEFT]: 'row-reverse',
  [PLACEMENT.RIGHT]: 'row',
  [PLACEMENT.BOTTOM]: 'column',
};

export const DIRECTIONS_MARGIN = {
  [PLACEMENT.TOP]: 'bottom',
  [PLACEMENT.LEFT]: 'right',
  [PLACEMENT.RIGHT]: 'left',
  [PLACEMENT.BOTTOM]: 'top',
};
