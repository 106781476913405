import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { RadioGroupWrapper } from './styled';

export const RadioGroupContext = React.createContext();

const RadioGroup = ({
  disabled,
  name,
  onChange,
  value,
  color,
  required,
  children,
  ...restProps
}) => {
  const [selectedValue, setValue] = useState(value);

  const onChangeRadio = ({ target }) => {
    const { value } = target;
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <RadioGroupWrapper disabled={disabled} {...restProps}>
      <RadioGroupContext.Provider
        value={{
          name,
          color,
          disabled,
          required,
          value: selectedValue,
          onChange: onChangeRadio,
        }}
      >
        {children}
      </RadioGroupContext.Provider>
    </RadioGroupWrapper>
  );
};

RadioGroup.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default RadioGroup;
