import React from 'react';
import { Icon } from 'Components/Icons';

const IcReturnsIcon = props => (
  <Icon viewBox="0 0 41 32" {...props}>
    <path d="M21.193.916a.729.729 0 00-.714 0 .687.687 0 00-.357.599v6.482h-7.731c-6.353 0-11.521 5-11.521 11.146s5.168 11.146 11.521 11.146h15.868c.395 0 .715-.31.715-.691v-4.299a.703.703 0 00-.715-.691H12.391c-2.255 0-5.643-3.283-5.643-5.465s3.388-5.465 5.643-5.465h7.731v8.102c0 .247.136.475.357.599a.732.732 0 00.714 0l18.14-10.134c.221-.124.357-.352.357-.599s-.136-.475-.357-.599L21.193.915z" />
  </Icon>
);

export default IcReturnsIcon;
