import React, { useState } from 'react';
import { Typography, BaseDropDown } from 'Components/UI';

import AppIcons from 'Components/AppIcons';

import {
  UserInfoWrapper,
  UserNameWrapper,
  UserDropdownWrapper,
  LogoutWrapper,
} from './styled';

const PROD = process.env.NODE_ENV === 'production';
const getInitials = name =>
  name &&
  name
    .split(' ')
    .map(w => w[0])
    .join('');

const UserInfo = ({ userInfo, onClickLogout }) => {
  const [userDropdownStatus, setUserDropdownStatus] = useState(false);
  return (
    <UserInfoWrapper>
      <BaseDropDown
        data-testid="username-option"
        className="userDropdown"
        isOpen={userDropdownStatus}
        onChangeIsOpen={setUserDropdownStatus}
        inputComponent={getInitials(userInfo.name)}
        position="right"
      >
        <UserDropdownWrapper>
          <UserNameWrapper>
            <Typography variant="h3" truncate>
              {userInfo.name}
            </Typography>
            <Typography className="userTitle" color="subtext" truncate>
              {userInfo.group.title}
            </Typography>
            {!PROD && (
              <Typography color="primary" truncate>
                ({ENV || 'demo'} / v{APP_VERSION})
              </Typography>
            )}
          </UserNameWrapper>
          <LogoutWrapper onClick={onClickLogout}>
            <Typography weight="bold">Logout</Typography>
            <AppIcons name="logout" width="1.2em" />
          </LogoutWrapper>
        </UserDropdownWrapper>
      </BaseDropDown>
    </UserInfoWrapper>
  );
};

export default UserInfo;
