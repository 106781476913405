import styled, { css } from 'styled-components';

export const EmptyElement = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  ${props => props.center && css`
    width: 100%;
    height: 100%;
    justify-content: center;
  `}
`;

export const EmptyChildren = styled.div`
  margin-bottom: ${props => props.theme.spacing(1.5)};
`;
