import React from 'react';
import { Icon } from 'Components/Icons';

const Dashboard = props => (
  <Icon viewBox="0 0 24 24" {...props} fill="none">
    <path
      opacity=".3"
      d="M5 5h4v6H5V5zm10 8h4v6h-4v-6zM5 17h4v2H5v-2zM15 5h4v2h-4V5z"
      fill="currentColor"
    />
    <path
      d="M3 12a1 1 0 001 1h6a1 1 0 001-1V4a1 1 0 00-1-1H4a1 1 0 00-1 1v8zm2-6a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 01-1 1H6a1 1 0 01-1-1V6zm8 14a1 1 0 001 1h6a1 1 0 001-1v-8a1 1 0 00-1-1h-6a1 1 0 00-1 1v8zm2-6a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 01-1 1h-2a1 1 0 01-1-1v-4zM14 3a1 1 0 00-1 1v4a1 1 0 001 1h6a1 1 0 001-1V4a1 1 0 00-1-1h-6zm5 3a1 1 0 01-1 1h-2a1 1 0 110-2h2a1 1 0 011 1zM3 20a1 1 0 001 1h6a1 1 0 001-1v-4a1 1 0 00-1-1H4a1 1 0 00-1 1v4zm2-2a1 1 0 011-1h2a1 1 0 110 2H6a1 1 0 01-1-1z"
      fill="currentColor"
    />
  </Icon>
);

export default Dashboard;
