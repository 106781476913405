import styled, { keyframes } from 'styled-components';
import { fade } from 'Components/Utils';

export const LoaderWrapper = styled.div`
  flex: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const slider = keyframes`
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
`;
export const LoadingBarWrapper = styled.div`
  background-color: ${props => fade(props.theme.palette.colors.primary, 0.3)};
  height: 4px;
  overflow: hidden;
  position: relative;

  span {
    width: auto;
    animation: ${slider} 2.5s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    background-color: ${props => props.theme.palette.colors.primary};
    top: 0;
    left: 0;
    width: 30%;
    bottom: 0;
    position: absolute;
    transition: transform 0.2s linear;
    transform-origin: left;
  }
`;
