import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: ${props => props.theme.dsSpacing(0.5)};
`;

export const HeaderPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.dsSpacing(2.5)};
  background-color: ${props => props.theme.palette.colors.default};
  border-radius: ${props => props.theme.config.borderRadius};
  cursor: pointer;

  ${props =>
    props.isOpen &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

export const ArrowAction = styled.div`
  display: flex;
  padding: ${props => props.theme.dsSpacing(0, 2.5)};
`;

export const Body = styled.div`
  padding: ${props => props.theme.dsSpacing(2.5)};
  background-color: ${props => props.theme.palette.colors.default};
  border-bottom-right-radius: ${props => props.theme.config.borderRadius};
  border-bottom-left-radius: ${props => props.theme.config.borderRadius};
`;

export const Footer = styled(Body)`
  margin-top: ${props => props.theme.dsSpacing(1)};
  border-radius: ${props => props.theme.config.borderRadius};
`;

export const BodyWrapper = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
`;
