import styled from 'styled-components';
import { lighten } from 'Components/Utils';

import { Typography } from '../Typography';

export const Badges = styled(Typography)`
  padding: ${props => props.theme.dsSpacing(1, 2)};
  color: ${props => props.theme.palette.contrastText[props.color]};
  white-space: nowrap;
  background: ${props => lighten(props.theme.palette.colors[props.color], 0.4)};
  border: 1px solid
    ${props => lighten(props.theme.palette.colors[props.color], 0.2)};
  border-radius: ${props => props.borderRadius}px;
`;
