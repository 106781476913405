import React from 'react';
import { Icon } from 'Components/Icons';

const SaathiRupee = props => (
  <Icon
    viewBox="0 0 31 32"
    {...props}
    style={{ width: '20px', height: '20px' }}
  >
    <path d="M21.487 11.439c-.481 1.834-1.383 3.239-2.705 4.216s-2.796 1.691-4.419 2.142L25.14 32.001h-7.395L10.17 21.856c-.872-1.172-1.661-2.022-2.367-2.548s-1.45-.789-2.232-.789h-.406v-3.743h2.66c.812 0 1.601-.038 2.367-.113s1.473-.225 2.119-.451c.646-.225 1.217-.556 1.713-.992s.864-1.03 1.105-1.781H4.352l1.353-4.013h9.514c-.271-1.022-.827-1.759-1.668-2.209s-1.984-.706-3.427-.767H4.352L5.705.437h20.201L24.553 4.45h-3.788c.481.902.797 1.894.947 2.976h4.193l-1.353 4.013h-3.066z" />
  </Icon>
);
export default SaathiRupee;
