import _merge from 'lodash/merge';

import { spacing } from 'Components/Utils';

import defaultConfig from './config';
import createPalette from './palette';
import createTypography from './typography';

const createAgDesignTheme = ({
  palette = {},
  typography = {},
  config = {},
} = {}) => {
  const configObj = _merge(defaultConfig, config);
  return {
    config: configObj,
    palette: createPalette(palette),
    spacing: spacing(configObj.spacing),
    dsSpacing: spacing(configObj.dsSpacing),
    typography: createTypography(typography, configObj.font),
  };
};

export default createAgDesignTheme;
