import styled from 'styled-components';
import { lighten } from 'Components/Utils';
import SearchInput from 'Components/SearchInput';

export const HeaderWrapper = styled.div`
  background: ${props => props.theme.palette.colors.white};
  padding: 8px 0;
  display: flex;
  align-items: center;
  margin-bottom: 1px;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  padding: ${props => props.theme.spacing(1, 2)};
  cursor: pointer;
`;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  padding: ${props => props.theme.spacing(0, 1)};
`;

export const SearchWrapper = styled.div`
  width: 400px;
  margin: 0 5%;
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  & .userDropdown {
    background: ${props => lighten(props.theme.palette.colors.primary, 0.8)};
    color: ${props => props.theme.palette.colors.primary};
    border: 1px solid
      ${props => lighten(props.theme.palette.colors.primary, 0.5)};
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    & .arrow-icon {
      display: none;
    }
  }
`;

export const LogoutWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: ${props => props.theme.spacing(1.5)};
  background: #eaeaea;
`;

export const UserNameWrapper = styled.div`
  padding: ${props => props.theme.spacing(1.5)};
  .userTitle {
    padding-top: ${props => props.theme.spacing(0.5)};
  }
`;

export const UserDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.palette.colors.white};
`;

export const GlobalSearch = styled(SearchInput)`
  height: 45px;
  background: ${props => props.theme.palette.colors.body};
`;
