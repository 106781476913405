import React from 'react';
import { Icon } from 'Components/Icons';

const OutwardPayment = props => (
  <Icon viewBox="0 0 23 18" {...props} fill="none">
    <path
      d="M19 10l-.705.705 2.79 2.795H0v1h21.085l-2.79 2.795L19 18l4-4-4-4z"
      fill="currentColor"
    />
    <path
      opacity=".3"
      d="M3 1h12v1.667H3V1zm0 5h12v5H3V6z"
      fill="currentColor"
    />
    <path
      d="M15.4 0H2.6c-.888 0-1.592.667-1.592 1.5L1 10.5c0 .832.712 1.5 1.6 1.5h12.8c.888 0 1.6-.668 1.6-1.5v-9c0-.833-.712-1.5-1.6-1.5zm0 10.5H2.6V6h12.8v4.5zm0-7.5H2.6V1.5h12.8V3z"
      fill="currentColor"
    />
  </Icon>
);

export default OutwardPayment;
