const validateBreakpoint = breakpointValue => {
  if (parseInt(breakpointValue, 10)) {
    return breakpointValue;
  }
  console.error(
    'No valid breakpoint or size specified for match media.',
    breakpointValue,
  );
  return '0';
};

export const getMatchMediaQuery = ({ minWidth, maxWidth }) => {
  if (!window || !window.matchMedia) {
    throw new Error('Window not available for match media');
  }

  let mediaString = '';
  if (minWidth && maxWidth) {
    mediaString = `(min-width: ${validateBreakpoint(
      minWidth,
    )}) and (max-width: ${validateBreakpoint(maxWidth)})`;
  } else if (minWidth) {
    mediaString = `(max-width: ${validateBreakpoint(minWidth)})`;
  } else if (maxWidth) {
    mediaString = `(min-width: ${validateBreakpoint(maxWidth)})`;
  }
  const matchObj = window.matchMedia(mediaString);
  return matchObj.matches;
};

export default getMatchMediaQuery;
