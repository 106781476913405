import React from 'react';

import MatchMediaQuery from 'Components/MatchMediaQuery';
import AppDrawer from './AppDrawer';
import MenuBar from './MenuBar';

import { SidebarWrapper } from './styled';

const Sidebar = ({ match, sidebarOpen, toggleSidebar }) => {
  return (
    <MatchMediaQuery maxWidth="tablet">
      {isDesktop =>
        isDesktop ? (
          <SidebarWrapper>
            <AppDrawer
              match={match}
              sidebarOpen={sidebarOpen}
              toggleSidebar={toggleSidebar}
            />
            <MenuBar match={match} toggleSidebar={toggleSidebar} />
          </SidebarWrapper>
        ) : (
          <AppDrawer
            match={match}
            sidebarOpen={sidebarOpen}
            toggleSidebar={toggleSidebar}
          />
        )
      }
    </MatchMediaQuery>
  );
};

export default Sidebar;
