import React from 'react';
import PropTypes from 'prop-types';
import { Cross } from 'Components/Icons';

import { HeaderWrapper, HeaderClose, HeaderChild } from './styled';

const ModalHeader = ({ onClose, component, closable }) => {
  return (
    <HeaderWrapper>
      <HeaderChild>{component}</HeaderChild>
      {closable && (
        <HeaderClose onClick={onClose}>
          <Cross />
        </HeaderClose>
      )}
    </HeaderWrapper>
  );
};

ModalHeader.defaultProps = {
  onClose: false,
  closable: true,
};

ModalHeader.propTypes = {
  onClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  closable: PropTypes.bool,
};

export default ModalHeader;
