import React from 'react';
import PropTypes from 'prop-types';

import { CircleLoader } from 'Components/Icons';

import { ButtonElement } from './styled';

const Button = ({ children, loading, disabled, ...restProps }) => (
  <ButtonElement loading={loading ? 1 : 0} disabled={loading || disabled} {...restProps}>
    {loading && <CircleLoader />}
    {children}
  </ButtonElement>
);

Button.defaultProps = {
  variant: 'button',
  color: 'primary',
  outline: false,
  block: false,
  loading: false,
  disabled: false,
  flat: false,
  size: 'medium',
};

Button.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  outline: PropTypes.bool,
  block: PropTypes.bool,
  loading: PropTypes.bool,
  flat: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Button;
