import {
  DRILLDOWN_GROUPS_CONST,
  WIDGET_GROUPS,
  TABS,
  PERMISSIONS,
} from './appConfig';

export const LS_KEY_CNST = {
  USER_OBJ: 'userInfo',
  USER_ID: 'username',
  USER_CODE: 'userId',
  USER_PROFILE: 'userprofile',
  FACILITY: 'facility',
  STATE: 'state',
  ALLOWED_FACILITY_LIST: 'accessibleFacilities',
  ALLOWED_STATE_LIST: 'accessibleStates',
  USERNAME: 'name',
  PAYMENTMODES: 'paymentModes',
  GROUP: 'groups',
  AUTH_TOKEN: 'X-Authorization-Token',
};

export const USER_ROLE = {
  LMD_OPERATION_MANAGER: 'lmd_operations_manager',
  LMD_WAREHOUSE_MANAGER: 'lmd_warehouse_manager',
  LMD_WAREHOUSE_EMPLOYEE: 'lmd_warehouse_employee',
  LMD_ACCOUNTS_GROUP: 'lmd_accounts_group',
  LMD_SENIOR_EXECUTIVE: 'lmd_senior_executive',
  LMD_CLUSTER_EXECUTIVE: 'lmd_cluster_executive',
  LMD_FRANCHISE_GROUP: 'lmd_franchise_group',
};

// If groups are added but no widgets added it means all widget will be allowed
// if no widget groups assigned then none of the widgets are allowed
export const USER_PERMISSION = {
  [USER_ROLE.LMD_OPERATION_MANAGER]: {
    title: 'Operation Manager',
    tabs: [
      TABS.SEARCH,
      TABS.DASHBOARD,
      TABS.DRILLDOWN,
      TABS.MISSING,
      TABS.PICK_TO_BE_SCHEDULED,
      TABS.SAATHI_RETURN_PACKAGES,
      TABS.PARTNERS_PROFILE,
      TABS.TRANSPORTS,
      TABS.SAATHI_PACKAGES,
      TABS.PENDING_ASSIGNMENT,
      TABS.ASSIGNED_PACKAGES,
      TABS.RETURN_REQUESTS,
      TABS.DELIVERY_VIA_STORE,
    ],
    defaultTab: TABS.DASHBOARD,
    groups: [
      WIDGET_GROUPS.PACKAGES,
      WIDGET_GROUPS.CHARTS_GRAPHS,
      WIDGET_GROUPS.PAYMENTS,
    ],
    permissions: [
      PERMISSIONS.MISSING_TICKET_UPDATE_STATUS,
      PERMISSIONS.PACKAGE_STATUS_UNDO,
      PERMISSIONS.PACKAGE_ARCHIVE,
    ],
  },
  [USER_ROLE.LMD_WAREHOUSE_MANAGER]: {
    title: 'Warehouse Manager',
    tabs: [
      TABS.DASHBOARD,
      TABS.SEARCH,
      TABS.OUTWARD_CONSIGNMENTS,
      TABS.RECEIVE_INTERSTATE_PACKAGES,
      TABS.SAATHI_RETURN_PACKAGES,
      TABS.REVERSE_LOGISTICS,
      TABS.MISSING,
      TABS.BOX_SCAN,
    ],
    defaultTab: TABS.DASHBOARD,
    groups: [WIDGET_GROUPS.PACKAGES],
    widgets: ['INWARD_MISSING', 'RETURN_MISSING'],
    permissions: [PERMISSIONS.MISSING_TICKET_UPDATE_STATUS],
  },
  [USER_ROLE.LMD_WAREHOUSE_EMPLOYEE]: {
    title: 'Warehouse Executive',
    tabs: [TABS.DISPATCHED_TO_LP, TABS.SEARCH, TABS.INTER_FACILITY_DISPATCH],
    defaultTab: TABS.DISPATCHED_TO_LP,
  },
  [USER_ROLE.CLUSTER_EXECUTIVE]: {
    title: 'Cluster Executive',
    tabs: [TABS.DASHBOARD, TABS.SEARCH, TABS.DRILLDOWN],
    defaultTab: TABS.DASHBOARD,
    groups: [WIDGET_GROUPS.PACKAGES],
  },
  [USER_ROLE.LMD_SENIOR_EXECUTIVE]: {
    title: 'Senior Executive',
    tabs: [TABS.DASHBOARD, TABS.SEARCH, TABS.DRILLDOWN],
    defaultTab: TABS.DASHBOARD,
    groups: [WIDGET_GROUPS.PACKAGES],
    drilldownGroup: [
      DRILLDOWN_GROUPS_CONST.FORWARD_LOGISTIC,
      DRILLDOWN_GROUPS_CONST.REVERSE_LOGISTIC,
    ],
  },
  [USER_ROLE.LMD_ACCOUNTS_GROUP]: {
    title: 'Accounts Executive',
    tabs: [
      TABS.MISSING_PACKAGES,
      TABS.PARTNERS_ACCOUNTS,
      TABS.SEARCH,
      TABS.OUTGOING_PAYMENT,
      TABS.INCOMING_PAYMENT,
    ],
    defaultTab: TABS.MISSING_PACKAGES,
  },
  [USER_ROLE.LMD_FRANCHISE_GROUP]: {
    title: 'Franchise',
    tabs: [
      TABS.DASHBOARD,
      TABS.INWARD_HISTORY,
      TABS.DELIVERED_PACKAGES,
      TABS.RETURN_HISTORY,
      TABS.PAYMENTS,
      TABS.RETURN_CONSIGNMENT_CHALLAN,
    ],
    defaultTab: TABS.DASHBOARD,
  },
};
