import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { TabMenu, TabMenuItem } from './styled';

const Tabs = ({
  tabs,
  defaultTabId,
  selectedTabId,
  onChangeTab,
  className,
  ...props
}) => {
  const [activeId, setActiveId] = useState(defaultTabId);

  useEffect(() => {
    if (selectedTabId) {
      setActiveId(selectedTabId);
    }
  }, [selectedTabId]);

  const setActiveTab = ({ target }) => {
    const { id } = target.dataset;
    if (id === activeId) {
      return;
    }
    if (!selectedTabId) {
      setActiveId(id);
    }
    if (onChangeTab) {
      onChangeTab(id);
    }
  };

  return (
    <TabMenu className={className}>
      {tabs.map(({ id, label, ...tabProps }) => {
        return (
          <TabMenuItem
            active={activeId === id}
            key={id}
            data-id={id}
            onClick={setActiveTab}
            {...tabProps}
            {...props}
          >
            {label}
          </TabMenuItem>
        );
      })}
    </TabMenu>
  );
};

Tabs.defaultProps = {
  color: 'primary',
  activecolor: 'primary',
  activeclass: 'active',
  className: '',
};

Tabs.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  activecolor: PropTypes.string,
  activeclass: PropTypes.string,
};

export default Tabs;
