import React from 'react';
import PropTypes from 'prop-types';

import { BarLoader, CircleLoader, DotLoader } from 'Components/Icons';

const LOADER_TYPE = {
  bar: BarLoader,
  circle: CircleLoader,
  dot: DotLoader,
};

const Loader = ({ type, size, ...restProps }) => {
  const LoaderTypeComponent = LOADER_TYPE[type];
  return <LoaderTypeComponent width={size} {...restProps} />;
};

Loader.defaultProps = {
  type: 'dot',
  size: '40',
};

Loader.propTypes = {
  type: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default Loader;
