import React from 'react';
import { Icon } from 'Components/Icons';

const Missing = props => (
  <Icon viewBox="0 0 24 24" {...props} fill="none">
    <path
      d="M19 10.41V15h2V7h-8v2h4.59L12 14.59 4.41 7 3 8.41l9 9 7-7z"
      fill="currentColor"
    />
  </Icon>
);

export default Missing;
