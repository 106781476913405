import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { TextareaElement } from './styled';

const Textarea = ({ onChange, autoResize, ...props }) => {
  const inputEl = useRef(null);

  const onChangeHandler = event => {
    if (onChange) {
      onChange(event);
    }
    if (autoResize) {
      inputEl.current.style.height = 'auto';
      inputEl.current.style.height = `${inputEl.current.scrollHeight}px`;
    }
  };

  return (
    <TextareaElement ref={inputEl} {...props} onChange={onChangeHandler} />
  );
};

Textarea.defaultProps = {
  autoResize: false,
};

Textarea.propTypes = {
  autoResize: PropTypes.bool,
};

export default Textarea;
