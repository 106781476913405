import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';

import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _orderBy from 'lodash/orderBy';
import _sortBy from 'lodash/sortBy';

import { Empty } from '../Empty';
import { Checkbox } from '../Form/Checkbox';
import Table from './TableElements';

import { RowLoader } from './styled';

/**
 * Rendering a Table Header
 *
 * render header based on column config
 * column config:
 * {
 *   header: 'First Name', // a react component or string
 *   key: 'firstName', // header uniq key available in data
 *   cellWidth: '200px', // cell width apply in table column
 *   sort: true, // activate sorting in column
 * }
 */
const TableHeader = ({
  columns,
  selectAll,
  onSelectAll,
  isSelectAll,
  selectable,
  sortBy,
  onClickHeaderCell,
  cellPadding,
}) => {
  return (
    <Table.Header>
      {selectable && (
        <Table.Cell className="selectCheckbox" cellWidth="auto">
          {selectAll && (
            <Checkbox checked={isSelectAll} onChange={onSelectAll} />
          )}
        </Table.Cell>
      )}
      {columns.map(column => {
        const { header, key, sort, ...restProps } = column;
        if (sort) {
          restProps.onClick = () => onClickHeaderCell(column);
        }
        const Cell = sort ? Table.SortCell : Table.Cell;
        return (
          <Cell
            key={key}
            cellKey={key}
            sortBy={sortBy}
            cellPadding={cellPadding}
            {...restProps}
          >
            {header}
          </Cell>
        );
      })}
    </Table.Header>
  );
};

const TableFooter = ({ columns, footer, selectable, cellPadding }) => {
  return (
    <Table.Footer>
      {selectable && <Table.Cell className="selectCheckbox" cellWidth="auto" />}
      {columns.map(({ key, cellWidth }) => {
        if (!footer[key]) {
          return null;
        }
        const { label, ...footerProps } = footer[key];

        return (
          <Table.Cell
            key={key}
            cellWidth={cellWidth}
            cellPadding={cellPadding}
            {...footerProps}
          >
            {label}
          </Table.Cell>
        );
      })}
    </Table.Footer>
  );
};

/**
 * Rendering a single row
 *
 * calculate row props and cell props based on rowProp and cellProp table props
 *
 * rowProps: pass row data
 * cellProps: pass cell data, cell key and row data
 */
const TableBodyRow = ({
  columns,
  rowData,
  cellProps,
  rowProps,
  selectable,
  onSelect,
  selectBy,
  selected,
  isRowSelectable,
  cellPadding,
  ...restProps
}) => {
  const rowProp = rowProps ? rowProps(rowData) : {};
  return (
    <Table.Row {...rowProp} {...restProps}>
      {selectable && (
        <Table.Cell className="selectCheckbox" cellWidth="auto">
          <Checkbox
            disabled={isRowSelectable ? !isRowSelectable(rowData) : false}
            checked={_get(rowData, selectBy) in selected}
            onChange={() => onSelect(rowData)}
          />
        </Table.Cell>
      )}
      {columns.map(({ key, cellWidth, render }) => {
        const cellData = _get(rowData, key);
        const cellProp = cellProps
          ? cellProps({ data: cellData, key, rowData })
          : {};

        return (
          <Table.Cell key={key} cellWidth={cellWidth} cellPadding={cellPadding}>
            {render
              ? render({ data: cellData, props: cellProp, rowData })
              : cellData}
          </Table.Cell>
        );
      })}
    </Table.Row>
  );
};

/**
 * rendering table data part.
 *
 * support 3 type of rendering
 * 1. Default Rendering
 * 2. window scrolling: it dynamically render rows that view in the viewport.
 *  use https://github.com/bvaughn/react-window library
 * 3. window scrolling and virtual data loading: support window scrolling plus it dynamically
 * load data when user scrolling. use https://github.com/bvaughn/react-window-infinite-loader/
 * for virtual loading
 */
const TableBody = ({
  tableData,
  dataKey,
  itemSize,
  totalDataLength,
  onLoadMore,
  onVirtualScroll,
  threshold,
  window,
  sortBy,
  isSelectAll,
  ...restProps
}) => {
  if (onLoadMore) {
    const itemCount =
      tableData.length < totalDataLength
        ? tableData.length + 1
        : tableData.length;
    const loadMoreItems = (startIndex, stopIndex) =>
      onLoadMore({ startIndex, stopIndex, sortBy, isSelectAll });
    return (
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={index => index < tableData.length}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            threshold={threshold}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                height={height}
                itemCount={itemCount}
                itemSize={itemSize}
                width={width}
                onItemsRendered={onItemsRendered}
                onScroll={onVirtualScroll}
                ref={ref}
                className="virtualContainer"
              >
                {({ index, style }) => {
                  if (index === tableData.length) {
                    return (
                      <RowLoader color="primary" type="circle" style={style} />
                    );
                  }
                  return (
                    <TableBodyRow
                      key={_get(tableData[index], dataKey)}
                      rowData={tableData[index]}
                      style={style}
                      {...restProps}
                    />
                  );
                }}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    );
  }

  if (window) {
    return (
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            height={height}
            itemCount={tableData.length}
            itemSize={itemSize}
            width={width}
            className="virtualContainer"
          >
            {({ index, style }) => {
              return (
                <TableBodyRow
                  key={_get(tableData[index], dataKey)}
                  rowData={tableData[index]}
                  style={style}
                  {...restProps}
                />
              );
            }}
          </FixedSizeList>
        )}
      </AutoSizer>
    );
  }
  return tableData.map(d => (
    <TableBodyRow key={_get(d, dataKey)} rowData={d} {...restProps} />
  ));
};

const TableComp = ({
  columns,
  header,
  footer,
  data,
  cellProps,
  rowProps,
  sticky,
  hover,
  striped,
  emptyMessage,
  selected,
  selectable,
  selectAll,
  selectBy,
  isRowSelectable,
  dataKey,
  onSelect,
  totalDataLength,
  itemSize,
  onLoadMore,
  onVirtualScroll,
  threshold,
  onSort,
  window,
  cellPadding,
}) => {
  const [tableData, setTableData] = useState(data);
  const [sortBy, setSortBy] = useState({ key: false, order: false });

  useEffect(() => {
    setTableData(data);
  }, [data]);

  /**
   * Apply default sorting in table data
   */
  const sortData = (dataToSort, sortBy, sortFuc) => {
    if (typeof sortFuc === 'function') {
      return dataToSort.sort((a, b) => sortFuc(a, b, sortBy.order));
    }
    return _orderBy(dataToSort, sortBy.key, sortBy.order);
  };

  const onSelectCheckBox = rowData => {
    const copySelected = { ...selected };
    const key = _get(rowData, selectBy);
    if (key in selected) {
      delete copySelected[key];
    } else {
      copySelected[key] = rowData;
    }
    onSelect(copySelected);
  };

  const onSelectAllCheckBox = ({ target }) => {
    const { checked } = target;
    onSelect(checked ? _keyBy(tableData, selectBy) : {});
  };

  /**
   * header cell click handler
   * this click handler bind only sorting is active in column
   *
   * when onSort props is available then it will pass all the
   * data to that function for custom sorting else apply default
   * sorting
   */
  const onClickHeaderCell = column => {
    const copySortBy = { ...sortBy };
    if (copySortBy.key === column.key) {
      copySortBy.order =
        copySortBy.order === Table.SORT_DIRECTIONS.DESCENDING
          ? Table.SORT_DIRECTIONS.ASCENDING
          : Table.SORT_DIRECTIONS.DESCENDING;
    } else {
      copySortBy.key = column.key;
      copySortBy.order = Table.SORT_DIRECTIONS.ASCENDING;
    }
    if (onSort) {
      onSort({ data, sortBy: copySortBy, column }).then(sortedData => {
        setSortBy(copySortBy);
        setTableData(sortedData);
      });
    } else {
      setSortBy(copySortBy);
      setTableData(sortData(data, copySortBy, column.sort));
    }
  };

  const isSelectAll =
    !!tableData.length && tableData.length === Object.keys(selected).length;

  return (
    <Table>
      {header && (
        <TableHeader
          columns={columns}
          selectAll={selectAll}
          isSelectAll={isSelectAll}
          selectable={selectable}
          onSelectAll={onSelectAllCheckBox}
          sortBy={sortBy}
          onClickHeaderCell={onClickHeaderCell}
          cellPadding={cellPadding}
        />
      )}
      {!tableData.length && <Empty message={emptyMessage} center />}
      <Table.Body sticky={sticky} hover={hover} striped={striped}>
        <TableBody
          tableData={tableData}
          selectable={selectable}
          columns={columns}
          cellProps={cellProps}
          rowProps={rowProps}
          selectBy={selectBy}
          selected={selected}
          isSelectAll={isSelectAll}
          selectable={selectable}
          isRowSelectable={isRowSelectable}
          dataKey={dataKey}
          totalDataLength={totalDataLength}
          itemSize={itemSize}
          onLoadMore={onLoadMore}
          onVirtualScroll={onVirtualScroll}
          threshold={threshold}
          onSelect={onSelectCheckBox}
          window={window}
          sortBy={sortBy}
          cellPadding={cellPadding}
        />
      </Table.Body>
      {footer && (
        <TableFooter
          columns={columns}
          footer={footer}
          selectable={selectable}
          cellPadding={cellPadding}
        />
      )}
    </Table>
  );
};

TableComp.defaultProps = {
  dataKey: 'id',
  header: true,
  footer: false,
  data: [],
  cellProps: false,
  rowProps: false,
  sticky: false,
  hover: false,
  striped: false,
  selected: {},
  selectBy: 'id',
  selectable: false,
  selectAll: false,
  emptyMessage: 'No Data Found',
  cellPadding: '12px',
  itemSize: 40,
  threshold: 30,
  window: false,
  onSort: false,
};

TableComp.propTypes = {
  dataKey: PropTypes.string,
  header: PropTypes.bool,
  footer: PropTypes.bool,
  data: [],
  cellProps: PropTypes.bool,
  rowProps: PropTypes.bool,
  sticky: PropTypes.bool,
  hover: PropTypes.bool,
  striped: PropTypes.bool,
  selected: {},
  selectBy: PropTypes.string,
  selectable: PropTypes.bool,
  selectAll: PropTypes.bool,
  emptyMessage: PropTypes.string,
  cellPadding: PropTypes.string,
  itemSize: PropTypes.number,
  threshold: PropTypes.number,
  window: PropTypes.bool,
  onSort: PropTypes.bool,
};

export const TableElements = Table;

export default TableComp;
