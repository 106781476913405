import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { canUseDOM } from 'Components/Utils';

import ModalComponent from './ModalComponent';
import { ModalBody, ModalFooter } from './styled';

/**
 * find dom element that portal is append react component on it.
 */
const modalRoot = canUseDOM()
  ? document.getElementById('portal') || document.body
  : null;

/**
 * Modal Component
 * append component outside react dom.
 * pass custom component for override default component
 *
 * destroyOnClose: un mount component from dom when closed
 */
const Modal = ({ visible, component: Component, destroyOnClose, ...rest }) => {
  const [unMounted, setUnMounted] = useState(true);
  // const eleRef = canUseDOM() ? document.createElement('div') : null;

  // useEffect(() => {
  //   modalRoot.appendChild(eleRef.current);
  //   return () => {
  //     modalRoot.removeChild(eleRef.current);
  //   };
  // }, []);

  useEffect(() => {
    if (visible) {
      setUnMounted(false);
    }
  }, [visible]);

  if (destroyOnClose && unMounted) {
    return null;
  }

  return canUseDOM()
    ? ReactDOM.createPortal(
        <Component setUnMounted={setUnMounted} visible={visible} {...rest} />,
        modalRoot,
      )
    : null;
};

Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

Modal.defaultProps = {
  component: ModalComponent,
  destroyOnClose: true,
};

export default Modal;
