import React from 'react';
import { Icon } from 'Components/Icons';

const BoxScan = props => (
  <Icon viewBox="0 0 1000 1000" {...props} fill="currentColor">
    <path d="M482.6 542.3L266.7 432.8c-4.9-2.5-10.4-2.3-15-.2L21 542.2c-6.4 2.5-11 8.8-11 16.2v299h.1c0 6.1 3.2 12.1 8.9 15.2L242.3 995l.1.1h.1l.1.1.1.1.1.1.1.1.1.1.1.1.1.1.1.1h.2l.1.1.2.1h.2l.1.1.3.1.1.1.2.1.1.1.1.1h.2l.2.1.1.1h.2l.2.1.2.1.2.1.1.1h.3l.2.1h.4l.1.1h1l.2.1h8.8l.2-.1h.8l.2-.1h.4l.2-.1h.3l.1-.1.2-.1.2-.1.1-.1h.2l.1-.1.2-.1h.2l.1-.1.2-.1.2-.1.1-.1.3-.1.1-.1h.2l.1-.1.1-.1h.2l.2-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1h.1l.1-.1 223.3-122.4c5.7-3.1 8.9-9.1 8.9-15.2h.1v-299c-2.2-7.3-6.7-13.6-13.1-16.1zm-223.8-74.8l48.6 24.6-187.2 97.6-55.1-30.2 193.7-92zm-24.2 483.9L44.4 847.1V587.5l58 31.8v57.4l43 20.3v-54.2l89.2 48.9v259.7zm17.1-289.6l-86-47.1 189-98.6 84.5 42.9-187.5 102.8zm727.4-119.5L763.2 432.8c-4.9-2.5-10.4-2.3-15-.2L517.4 542.2c-6.4 2.5-11 8.8-11 16.2v299h.1c0 6.1 3.2 12.1 8.9 15.2L738.8 995l.1.1h.1l.1.1.1.1.1.1.1.1.1.1.1.1.1.1.1.1h.2l.1.1.2.1h.2l.1.1.3.1.1.1.2.1.1.1.1.1h.2l.2.1.1.1h.2l.2.1.2.1.2.1.1.1h.3l.2.1h.4l.1.1h1l.2.1h8.7l.2-.1h.8l.2-.1h.4l.2-.1h.3l.1-.1.2-.1.2-.1.1-.1h.2l.1-.1.2-.1h.2l.1-.1.2-.1.2-.1.1-.1.3-.1.1-.1h.2l.1-.1.1-.1h.2l.2-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1h.1l.1-.1L981 872.6c5.7-3.1 8.9-9.1 8.9-15.2h.1v-299c0-7.3-4.5-13.6-10.9-16.1zm-223.8-74.8l48.6 24.6-187.2 97.6-55.1-30.2 193.7-92zM731 951.4L540.9 847.1V587.5l58 31.8v57.4l43 20.3v-54.2l89.2 48.9v259.7h-.1zm17.2-289.6l-86-47.1 189-98.6 84.5 42.9-187.5 102.8z" />
    <path d="M736 113.5L520.1 4c-4.9-2.5-10.4-2.3-15-.2L274.4 113.5c-6.4 2.5-11 8.8-11 16.2v299h.1c0 6.1 3.2 12.1 8.9 15.2l223.3 122.4.1.1h.1l.1.1.1.1.1.1.1.1.1.1.1.1.1.1.1.1h.2l.1.1.2.1h.2l.1.1.3.1.1.1.2.1.1.1.1.1h.2l.2.1.1.1h.2l.2.1.2.1.2.1.1.1h.3l.2.1h.4l.1.1h1l.2.1h8.8l.2-.1h.8l.2-.1h.4l.2-.1h.3l.1-.1.2-.1.2-.1.1-.1h.2l.1-.1.2-.1h.2l.1-.1.2-.1.2-.1.1-.1.3-.1.1-.1h.2l.1-.1.1-.1h.2l.2-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1h.1l.1-.1L738 443.8c5.7-3.1 8.9-9.1 8.9-15.2h.1v-299c0-7.3-4.6-13.6-11-16.1zM524.5 44.7l48.6 24.6-187.2 97.6-55.1-30.2 193.7-92zm-36.2 483.9L298.1 424.3V164.7l58 31.8V254l43 20.3v-54.2l89.2 48.9v259.6zm16.9-295.5l-86-47.1 189-98.6 84.5 42.9-187.5 102.8z" />
  </Icon>
);

export default BoxScan;
