import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, LabelWrapper } from './styled';

const FormControlLabel = ({
  id,
  name,
  disabled,
  labelText,
  labelTextPlacement,
  labelProps,
  children,
  ...restProps
}) => {

  return (
    <Wrapper
      element="label"
      htmlFor={id || name}
      labelTextPlacement={labelTextPlacement}
      disabled={disabled}
      {...restProps}
    >
      {children({ disabled, id, name })}
      {
        labelText && (
          <LabelWrapper labelTextPlacement={labelTextPlacement} {...labelProps}>
            {labelText}
          </LabelWrapper>
        )
      }
    </Wrapper>
  );
};

FormControlLabel.defaultProps = {
  labelTextPlacement: 'right',
  disabled: false,
  labelProps: {},
};

FormControlLabel.propTypes = {
  labelText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  labelTextPlacement: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormControlLabel;
