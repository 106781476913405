import React from 'react';

import { Icon } from './index';

export const Check = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fillRule="evenodd">
      <ellipse cx="9.96" cy="10" rx="9.96" ry="10" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="#FFF"
        strokeWidth="1.155"
        d="M13.678 7.169l-5.215 5.224-3.002-2.122a.328.328 0 0 0-.414.036c-.103.108-.085.268.04.357l3.232 2.279a.328.328 0 0 0 .408-.03l5.494-5.495a.227.227 0 0 0 .053-.25.285.285 0 0 0-.223-.164.322.322 0 0 0-.277.085l-.096.08z"
      />
    </g>
  </Icon>
);

export default Check;
