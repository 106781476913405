import _sortBy from 'lodash/sortBy';

const WEIGHT_UNIT = 1000;

/**
 * send list of data in get params
 *
 * Ex: historyStatus='status1'&historyStatus='status2'
 */
export const urlParams = obj => {
  const params = new URLSearchParams();

  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach(listEle => params.append(key, listEle));
    } else {
      params.append(key, obj[key]);
    }
  });

  return params;
};

export const isSameDateAs = (date_1, date_2) =>
  date_1.getFullYear() === date_2.getFullYear() &&
  date_1.getMonth() === date_2.getMonth() &&
  date_1.getDate() === date_2.getDate();

export const calculateMaxTime = date => {
  let isToday = isSameDateAs(new Date(), date);
  if (isToday) {
    return new Date();
  }
  let returnDate = new Date();
  returnDate.setHours(23);
  returnDate.setMinutes(59);
  return returnDate;
};

export const weightFormat = input => {
  const weight = Number(input);
  return weight >= WEIGHT_UNIT
    ? `${(weight / WEIGHT_UNIT).toFixed(2)} Kg`
    : `${weight || 0} gm`;
};

export const totalPackageWeight = (packages, format = true) => {
  const packagesWeight = packages.reduce((total, pkg) => {
    const weight = parseFloat(pkg.weight);
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(weight)) {
      return total + weight;
    }
    return total;
  }, 0);

  return format ? weightFormat(packagesWeight) : packagesWeight;
};

export const arrayMove = (array, from, to) => {
  array.splice(to, 0, array.splice(from, 1)[0]);
  return array;
};

export const INMetricSuffix = (input, decimal = 2) => {
  const denomination = [1000, 100000, 10000000];
  const suffixes = ['K', 'L', 'Cr'];
  let baseIndex = 0;

  if (input < 1000) {
    return input;
  }
  if (input >= denomination[0] && input < denomination[1]) {
    baseIndex = 0;
  } else if (input >= denomination[1] && input < denomination[2]) {
    baseIndex = 1;
  } else if (input >= denomination[2]) {
    baseIndex = 2;
  }

  return (
    (input / denomination[baseIndex]).toFixed(decimal) + suffixes[baseIndex]
  );
};

export const sortSlotTransportsByTime = transports => {
  const copyTransport = { ...transports };
  if (copyTransport.futureSlots) {
    copyTransport.futureSlots = _sortBy(copyTransport.futureSlots, ['time']);
  }
  if (copyTransport.todaysSlots) {
    copyTransport.todaysSlots = _sortBy(copyTransport.todaysSlots, ['time']);
  }
  return copyTransport;
};

export const hasSubstring = (str1 = '', str2 = '') =>
  str1.toLowerCase().includes(str2.toLowerCase());

export const downloadFileByUrl = link => {
  window.open(link);
  window.onload = () => {
    window.close();
  };
};

export const mergeValidator = (...validator) => {
  return value => {
    for (let i = 0; i < validator.length; i += 1) {
      const res = validator[i](value);
      if (res) {
        return res;
      }
    }
    return null;
  };
};

export const dynamicSort = property => {
  let sortOrder = 1;
  // to sort reversly(descending order) use -property
  if (property[0] === '-') {
    sortOrder = -1;
    // eslint-disable-next-line no-param-reassign
    property = property.substr(1);
  }

  return function stringSort(a, b) {
    if (sortOrder === -1) {
      return b[property].localeCompare(a[property]);
    }
    return a[property].localeCompare(b[property]);
  };
};

export const verifyAndGetNumberOfBoxes = numberOfBoxes => {
  if (
    numberOfBoxes === 1 ||
    numberOfBoxes === 0 ||
    numberOfBoxes === null ||
    numberOfBoxes === undefined
  ) {
    return '-';
  }

  return numberOfBoxes;
};
