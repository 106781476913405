import React from 'react';
import { Icon } from 'Components/Icons';

const DispatchToLP = props => (
  <Icon viewBox="0 0 28 28" {...props} fill="none">
    <path
      opacity=".3"
      d="M14 11.667A2.333 2.333 0 1014 7a2.333 2.333 0 000 4.667zM14 17.5c-3.15 0-6.767 1.505-7 2.345V21h14v-1.167c-.233-.828-3.85-2.333-7-2.333z"
      fill="currentColor"
    />
    <path
      d="M18.083 9.333A4.082 4.082 0 0114 13.417a4.082 4.082 0 01-4.083-4.084A4.082 4.082 0 0114 5.25a4.082 4.082 0 014.083 4.083zm-1.166 0A2.924 2.924 0 0014 6.417a2.924 2.924 0 00-2.917 2.916A2.924 2.924 0 0014 12.25a2.924 2.924 0 002.917-2.917zM7.583 20.417v-.432a.812.812 0 01.058-.06c.088-.084.224-.187.414-.304.378-.232.911-.483 1.55-.715 1.279-.464 2.903-.823 4.395-.823 1.492 0 3.116.36 4.395.822.64.23 1.173.48 1.551.712.19.116.327.219.415.302a.856.856 0 01.056.058v.44H7.583z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.167"
    />
    <path
      d="M20.417 19.115v1.302H7.583v-1.284c.045-.175.213-.472.584-.852.38-.39.91-.805 1.541-1.186 1.27-.767 2.856-1.345 4.292-1.345s3.023.578 4.293 1.343c.63.38 1.162.794 1.541 1.18.37.378.538.671.583.842z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.167"
    />
    <path
      d="M23 19l-.705.705 2.79 2.795H4v1h21.085l-2.79 2.795L23 27l4-4-4-4z"
      fill="currentColor"
    />
  </Icon>
);

export default DispatchToLP;
