import React from 'react';

import ToggleMenuIcon from './ToggleMenuIcon';
import SubMenu from './SubMenu';
import Logout from './Logout';
import DashboardIcon from './Dashboard';
import ReportIcon from './Reports';
import DrillDown from './DrillDown';
import OutwardConsignmentsIcon from './OutwardConsignments';
import ReverseLogisticsIcon from './ReverseLogistics';
import MissingIcon from './Missing';
import Rupee from './Rupee';
import PartnerAccounts from './PartnerAccounts';
import DispatchToLP from './DispatchToLP';
import InterFacilityDispatch from './InterFacilityDispatch';
import IcReturns from './IcReturns';
import OutwardPayment from './OutwardPayment';
import PartnersProfile from './PartnersProfile';
import PartnersTransport from './PartnersTransport';
import AssignedPackages from './AssignedPackages';
import Assignments from './Assignments';
import ReturnRequest from './ReturnRequest';
import InwardPayment from './InwardPayment';
import InwardHistory from './InwardHistory';
import DeliveredPackages from './DeliveredPackages';
import ReturnsHistory from './ReturnsHistory';
import Payments from './Payments';
import BoxScan from './BoxScan';
import SaathiRupee from './SaathiRupee';
import InterstatePackage from './InterstatePackage';
import PickUpSchedule from './PickUpSchedule';
import SaathiReturns from './SaathiReturns';
import DeliveryViaStore from './DeliveryViaStore';

const IconMapping = {
  toggleMenuIcon: ToggleMenuIcon,
  subMenu: SubMenu,
  logout: Logout,
  dashboard: DashboardIcon,
  drilldown: DrillDown,
  report: ReportIcon,
  outwardConsignment: OutwardConsignmentsIcon,
  reverseLogistics: ReverseLogisticsIcon,
  missing: MissingIcon,
  rupee: Rupee,
  partnerAccounts: PartnerAccounts,
  dispatchToLP: DispatchToLP,
  interFacilityDispatch: InterFacilityDispatch,
  icReturn: IcReturns,
  outwardPayment: OutwardPayment,
  partnersProfile: PartnersProfile,
  partnersTransport: PartnersTransport,
  assignedPackages: AssignedPackages,
  assignments: Assignments,
  returnRequest: ReturnRequest,
  inwardPayment: InwardPayment,
  inwardHistory: InwardHistory,
  deliveredPackages: DeliveredPackages,
  returnsHistory: ReturnsHistory,
  payments: Payments,
  boxScan: BoxScan,
  saathiRupee: SaathiRupee,
  InterstatePackage: InterstatePackage,
  PickUpSchedule: PickUpSchedule,
  SaathiReturns: SaathiReturns,
  DeliveryViaStore: DeliveryViaStore,
};

const AppIcons = ({ name, ...restProps }) => {
  const IconComponent = IconMapping[name];
  return <IconComponent {...restProps} />;
};

export default AppIcons;
