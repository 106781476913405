import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

/*
  Position: top|bottom|left|right
  Example:
    <div data-tip data-for="testid">Element on which tooltip to be shown</div>
    <Tooltip dataid="testid" position="top">
      tooltip content .....
    </Tooltip>

*/
const Tooltip = ({ position, className, dataid, children, ...restProps }) => (
  <ReactTooltip id={dataid} dataid={dataid} className={className} place={position} {...restProps}>
    {children}
  </ReactTooltip>
);

Tooltip.rebuild = ReactTooltip.rebuild;

Tooltip.defaultProps = {
  position: 'top',
};

Tooltip.propTypes = {
  position: PropTypes.string,
  dataid: PropTypes.string.isRequired,
};

export default Tooltip;
