import { createSelector } from 'reselect';
import _pick from 'lodash/pick';
import _get from 'lodash/get';

import {
  USER_ROLE,
  LS_KEY_CNST,
  USER_PERMISSION,
} from 'Utilities/constants/user';
import {
  DRILLDOWN_GROUPS,
  TABS,
  SUB_TABS,
} from 'Utilities/constants/appConfig';

export const getUser = state => state.user;

export const getUserGroup = state =>
  state.user && state.user.groups && state.user.groups[0];

export const getSelectedState = state => state.selectedState;

export const getSelectedStateId = state =>
  state.selectedState && state.selectedState.id;

export const getAuthToken = createSelector(
  [getUser],
  user => user && user[LS_KEY_CNST.AUTH_TOKEN],
);

export const isAuthorised = createSelector([getUser], user => {
  return (
    !!(user && user[LS_KEY_CNST.USER_CODE]) &&
    user[LS_KEY_CNST.GROUP][0] &&
    USER_PERMISSION[user[LS_KEY_CNST.GROUP][0]]
  );
});

export const getAccesibleStates = createSelector([getUser], user =>
  user ? user[LS_KEY_CNST.ALLOWED_STATE_LIST] : [],
);

export const getUserFacility = createSelector(
  [getUser],
  user => user && user[LS_KEY_CNST.FACILITY],
);

export const getUserInfo = createSelector(
  [getUser, getUserGroup],
  (user, userGroup) => ({
    username: user && user[LS_KEY_CNST.USER_ID],
    name: user && user[LS_KEY_CNST.USERNAME],
    group: USER_PERMISSION[userGroup],
  }),
);

export const hasReportAccess = createSelector(
  [getUser],
  user => user && _get(user, 'tabsEnabled.reportsEnabled'),
);

export const getSelectedFacility = createSelector([getSelectedState], state => [
  state.id,
]);

export const getAccessibleWidgetGroup = createSelector(
  [getUserGroup],
  group => USER_PERMISSION[group].groups,
);

export const getAccessibleDrillDownGroup = createSelector(
  [getUserGroup],
  group => {
    if (!group) {
      return null;
    }
    return USER_PERMISSION[group].drilldownGroup
      ? _pick(DRILLDOWN_GROUPS, USER_PERMISSION[group].drilldownGroup)
      : DRILLDOWN_GROUPS;
  },
);

export const getAccessibleTabs = createSelector(
  [getUserGroup, hasReportAccess, getAccessibleDrillDownGroup],
  (group, reportAccess, drillDownGroups) => {
    const tabs = group ? USER_PERMISSION[group].tabs : [];
    const accessibleTabs = reportAccess ? [TABS.REPORTS, ...tabs] : tabs;
    accessibleTabs.forEach(tab => {
      if (tab.id === TABS.DRILLDOWN.id) {
        tab.subTabs = Object.values(drillDownGroups);
      } else {
        tab.subTabs = SUB_TABS[tab.id] || [];
      }
    });
    return accessibleTabs;
  },
);

export const getDefaultTab = createSelector(
  [getUserGroup, hasReportAccess],
  (group, reportAccess) =>
    group && (reportAccess ? TABS.REPORTS : USER_PERMISSION[group].defaultTab),
);

export const hasPermissions = createSelector(
  [getUserGroup, (state, key) => key],
  (group, key) => {
    const { permissions = [] } = USER_PERMISSION[group];
    return permissions.includes(key);
  },
);

export const isOpsUser = createSelector(
  [getUser],
  user =>
    user &&
    user[LS_KEY_CNST.GROUP].indexOf(USER_ROLE.LMD_OPERATION_MANAGER) >= 0,
);

export const isWHManagerUser = createSelector(
  [getUser],
  user =>
    user &&
    user[LS_KEY_CNST.GROUP].indexOf(USER_ROLE.LMD_WAREHOUSE_MANAGER) >= 0,
);
