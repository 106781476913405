import React, { useCallback, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import { Modal } from '../Modal';
import { BodyWrapper, DrawerWrapper } from './styled';

/*
 * Drawer Component
 * positions: left or right
 * maskClosable: close drawer when click outside of drawer
 */

const DrawerComponent = ({
  visible,
  children,
  onClose,
  maskClosable,
  isHoverTransition,
  setUnMounted,
  position,
}) => {
  const [isVisible, setIsVisible] = useState(visible);

  const drawerExtraProps = {};

  useEffect(() => {
    if (visible) {
      setIsVisible(visible);
    }
  }, [visible]);

  const onCloseModal = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, []);

  const onClickBody = useCallback(() => {
    if (maskClosable) {
      onCloseModal();
    }
  }, []);

  const onClickModalWrapper = useCallback(event => {
    event.stopPropagation();
  }, []);

  const onExitedDrawer = () => {
    setIsVisible(visible);
    setUnMounted(true);
  };

  if (isHoverTransition) {
    drawerExtraProps['onMouseLeave'] = onClickBody;
  }

  return (
    <CSSTransition in={visible} timeout={300} classNames="model-body-animation">
      <BodyWrapper onClick={onClickBody} visible={isVisible}>
        <CSSTransition
          in={visible}
          timeout={300}
          classNames="drawer-animation"
          onExited={onExitedDrawer}
        >
          <DrawerWrapper
            onClick={onClickModalWrapper}
            position={position}
            {...drawerExtraProps}
          >
            {children}
          </DrawerWrapper>
        </CSSTransition>
      </BodyWrapper>
    </CSSTransition>
  );
};

/*
 * Drawer Component
 * this component is used modal to append drawer into the dom.
 * we passed custom component in modal for drawer
 */

const Drawer = props => {
  return <Modal component={DrawerComponent} {...props} />;
};

Drawer.defaultProps = {
  color: 'primary',
  element: 'span',
  borderRadius: 30,
  position: 'left',
  isHoverTransition: false,
};

Drawer.propTypes = {
  color: PropTypes.string,
  element: PropTypes.string,
  borderRadius: PropTypes.number,
  isHoverTransition: PropTypes.bool,
};

export default Drawer;
