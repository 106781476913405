/* eslint-disable no-param-reassign */
import _sortBy from 'lodash/sortBy';
import _keyBy from 'lodash/keyBy';
import { arrayMove } from 'Utilities';

import { selectors } from 'State/user';
// eslint-disable-next-line import/no-cycle
import {
  userDashboardPreference,
  saveUserDashboardPreference,
} from 'Services/dashboard';
import * as types from './types';

export const loadWidgetData = widgets => dispatch => {
  return dispatch({
    type: types.WIDGET_LOAD_ALL,
    widgets,
  });
};

export const loadDashboardWidgets = widgets => dispatch => {
  return userDashboardPreference()
    .then(({ dashboard }) => {
      // Expected response Object: {key: "IN_TRANSIT", position: 2, visible: true/false}
      // If empty/ array of widgets as response --> Dispatch GET_DEFAULT_WIDGETOBJ action

      const modifiedWidgets = {};
      const storedDashboard = _keyBy(dashboard, 'key');

      Object.keys(widgets).forEach((key, index) => {
        const { position = index, visible = true } = storedDashboard[key] || {};
        modifiedWidgets[key] = {
          groupName: widgets[key].groupName,
          position,
          visible,
        };
      });

      dispatch(loadWidgetData(modifiedWidgets));
      return modifiedWidgets;
    })
    .catch(error => {
      console.log(error);
    });
};

export const updateWidgetRequestBody = (widgetId, body) => dispatch => {
  return dispatch({
    type: types.WIDGET_UPDATE_FILTERS,
    widgetId,
    body,
  });
};

export const saveUserDashboard = widgets => (dispatch, state) => {
  const { username } = selectors.getUserInfo(state());
  const payload = [];
  Object.keys(widgets).forEach(key => {
    const { position, visible } = widgets[key];
    payload.push({ key, position, visible });
  });

  dispatch(loadWidgetData(widgets));
  return saveUserDashboardPreference(username, payload).catch(
    ({ response: { data } }) => {
      console.error(data.message);
    },
  );
};

export const removeWidget = (widgetId, widgets) => dispatch => {
  dispatch(
    saveUserDashboard({
      ...widgets,
      [widgetId]: { ...widgets[widgetId], visible: false },
    }),
  );
};

export const changeVisibility = (widgets, visibleList) => dispatch => {
  Object.keys(visibleList).forEach(group => {
    Object.keys(visibleList[group]).forEach(key => {
      widgets[key].visible = visibleList[group][key].visible;
    });
  });

  dispatch(saveUserDashboard(widgets));
};

export const changeWidgetPosition = (
  widgets,
  oldIndex,
  newIndex,
) => dispatch => {
  widgets = { ...widgets };
  Object.keys(widgets).forEach(key => {
    widgets[key].key = key;
  });

  const visibleWidgets = _sortBy(
    Object.values(widgets).filter(w => w.visible),
    'position',
  );
  const inVisibleWidgets = Object.values(widgets).filter(w => !w.visible);

  let widgetList = arrayMove(visibleWidgets, oldIndex, newIndex);
  widgetList = [...widgetList, ...inVisibleWidgets].map((value, index) => ({
    ...value,
    position: index,
  }));

  dispatch(saveUserDashboard(_keyBy(widgetList, 'key')));
};
