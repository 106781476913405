import * as types from './types';

const defaultState = {
  list: [],
  selectedFranchise: null,
};

const interStateFranchise = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_INTERSTATE_FRANCHISE_SUCCESS:
      return {
        ...state,
        ...{ list: action.payload, selectedFranchise: action.payload[0] || {} },
      };

    case types.GET_INTERSTATE_FRANCHISE_FAILURE:
      return defaultState;

    case types.SET_SELECTED_FRANCHISE:
      return { ...state, ...{ selectedFranchise: action.payload } };

    default:
      return state;
  }
};

export default {
  interStateFranchise,
};
