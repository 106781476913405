import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import { LEGACY_LINKS } from 'Utilities/constants/appConfig';

import { actions, selectors } from 'State/user';

import TabComponentsMap from 'Pages';
import { AppLoadingBar } from 'Components/AppLoader';
import { AppLayout } from './Layout';
import { SiteProvider } from './SiteContext';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      sidebarOpen: false,
    };
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  toggleSidebar() {
    const { sidebarOpen } = this.state;
    this.setState({
      sidebarOpen: !sidebarOpen,
    });
  }

  routeToDefaultUrl(url) {
    window.location.href = `${window.config.OLD_SITE_URL}/${url}`;
  }

  render() {
    const {
      selectedStateId,
      tabs,
      match,
      defaultTab,
      history,
      userInfo,
      logoutUser,
      isUserAuthorised,
      selectedState,
    } = this.props;

    const { error } = this.state;

    if (error) return <div>Something went wrong.</div>;

    if (!isUserAuthorised) {
      history.push('/login');
    }

    if (!selectedStateId) return 'Loading...';
    return (
      <SiteProvider
        value={{
          userInfo,
          logoutUser,
          tabs,
          selectedStateId,
          selectedState,
        }}
      >
        <AppLayout sideBarProps={{}} history={history} match={match}>
          <React.Suspense fallback={<AppLoadingBar />}>
            <Switch>
              {tabs.map(t => (
                <Route
                  key={t.id}
                  path={`${match.url}/${t.url}`}
                  component={TabComponentsMap[t.id]}
                />
              ))}
              {LEGACY_LINKS[defaultTab.id] ? (
                this.routeToDefaultUrl(LEGACY_LINKS[defaultTab.id])
              ) : (
                <Redirect from="/app" to={`${match.url}/${defaultTab.url}`} />
              )}
            </Switch>
          </React.Suspense>
        </AppLayout>
      </SiteProvider>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getUserInfo(state),
  isUserAuthorised: selectors.isAuthorised(state),
  tabs: selectors.getAccessibleTabs(state),
  defaultTab: selectors.getDefaultTab(state),
  selectedStateId: selectors.getSelectedStateId(state),
  selectedState: selectors.getSelectedState(state),
});

export default connect(mapStateToProps, {
  logoutUser: actions.logoutUser,
})(App);
