import styled from 'styled-components';
import { fade } from 'Components/Utils';

export const Wrapper = styled.div`
  width: 100%;
  margin: ${props => props.theme.dsSpacing(2, 0)};
  padding: ${props => props.theme.dsSpacing(3)};
  background: ${props => fade(props.theme.palette.colors[props.color], 0.1)};
  border-radius: ${props => props.theme.config.borderRadius};
`;

export default Wrapper;
