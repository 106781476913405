import React from 'react';

import AppIcons from 'Components/AppIcons';
import { useSiteValue } from 'App/SiteContext';
import { LEGACY_LINKS } from 'Utilities/constants/appConfig';
import {
  Separator,
  Link,
  ExternalLink,
  SubTabWrapper,
  SubMenuLink,
  MenuIcon,
  SidebarItemWrapper,
} from './styled';

const SideBarItem = ({
  icon = 'subMenu',
  url,
  id,
  label,
  showLabel,
  hasSubMenu,
  toggleSidebar,
}) => {
  const toggleMenuBar = ({ type }) => {
    console.log(type);
    toggleSidebar();
  };

  return (
    <SidebarItemWrapper onMouseEnter={showLabel ? undefined : toggleMenuBar}>
      {LEGACY_LINKS[id] ? (
        <ExternalLink
          href={`${window.config.OLD_SITE_URL}/${LEGACY_LINKS[id]}`}
          showlabel={showLabel ? showLabel.toString() : undefined}
          hassubmenu={hasSubMenu ? hasSubMenu.toString() : undefined}
        >
          <MenuIcon>
            <AppIcons name={icon} width="1.8em" />
          </MenuIcon>
          {showLabel && label}
        </ExternalLink>
      ) : (
        <Link
          to={url}
          showlabel={showLabel ? showLabel.toString() : undefined}
          hassubmenu={hasSubMenu ? hasSubMenu.toString() : undefined}
        >
          <MenuIcon>
            <AppIcons name={icon} width="1.8em" />
          </MenuIcon>
          {showLabel && label}
        </Link>
      )}
    </SidebarItemWrapper>
  );
};

const SubMenuItem = ({ url, label }) => (
  <SubMenuLink to={url}>
    <MenuIcon>
      <AppIcons name="subMenu" width="0.9em" />
    </MenuIcon>
    {label}
  </SubMenuLink>
);

const MenuTabs = ({ tabs, match, subMenu, toggleSidebar, showLabel }) => {
  if (!tabs.length) {
    return null;
  }
  return tabs.map(({ id, url, subTabs, ...restProps }) => (
    <React.Fragment key={id}>
      <SideBarItem
        url={`${match.path}/${url}`}
        id={id}
        showLabel={showLabel}
        hasSubMenu={subMenu && !!subTabs}
        toggleSidebar={toggleSidebar}
        {...restProps}
      />
      {subMenu && !!subTabs.length && (
        <SubTabWrapper isActive>
          {subTabs.map(tab => (
            <SubMenuItem
              key={tab.id}
              {...tab}
              url={`${match.path}/${url}/${tab.url}`}
            />
          ))}
        </SubTabWrapper>
      )}
    </React.Fragment>
  ));
};

const MenuBar = props => {
  const { tabs } = useSiteValue();
  const visibleTabs = tabs.filter(t => !t.invisible);
  const mainTabs = visibleTabs.filter(t => t.main);
  const restTabs = visibleTabs.filter(t => !t.main);

  return (
    <>
      <MenuTabs tabs={mainTabs} {...props} />
      {!!mainTabs.length && <Separator />}
      <MenuTabs tabs={restTabs} {...props} />
    </>
  );
};

MenuBar.defaultProps = {
  showLabel: false,
};

export default MenuBar;
