import { createAgDesignTheme } from 'Components/UI';

const config = {
  font: {
    family: 'Noto Sans, sans-serif',
  },
  spacing: 8,
  lightenRatio: 0.05,
  darkenRatio: 0.1,
  disabledOpacity: 0.4,
  zIndex: {
    modal: 100,
    dropdown: 200,
    tooltip: 300,
    drawer: 400,
    toast: 500,
  },
};
const palette = {
  colors: {
    primary: '#27A262',
    secondary: '#D50000',
    default: '#EAEAEA',
    body: '#EAEAEA',
    text: '#333333',
    label: '#000000',
    subtext: '#999999',
    disabled: '#DCDCDC',
    info: '#f9ac01;',
    error: '#D50000',
  },
  borderColors: {
    default: '#EAEAEA',
    divider: '#EAEAEA',
  },
};

const typography = {
  h1: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  h2: {
    fontSize: 18,
    fontWeight: 'normal',
  },
  h3: {
    fontSize: 16,
    fontWeight: 'normal',
  },
  body: {
    fontSize: 14,
    fontWeight: 'normal',
  },
  label: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  helpText: {
    fontSize: 12,
    fontWeight: 'normal',
  },
  subText: {
    fontSize: 12,
    fontWeight: 'normal',
  },
  button: {
    fontSize: 14,
    fontWeight: 'normal',
    'text-transform': 'capitalize',
  },
};

const theme = createAgDesignTheme({ config, palette, typography });

export default theme;
