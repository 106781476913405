import { setUserSelectedState } from 'Utilities/localStorage';
import * as types from './types';

const user = (state = {}, action) => {
  switch (action.type) {
    case types.USERS_LOGIN:
      return action.user;

    case types.USERS_LOGOUT:
      return null;

    default:
      return state;
  }
};

const selectedState = (state = {}, action) => {
  switch (action.type) {
    case types.SET_USER_STATE:
      setUserSelectedState(action.state);
      return action.state;
    default:
      return state;
  }
};

export default {
  user,
  selectedState,
};
