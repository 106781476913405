import React from 'react';

import { useSiteValue } from 'App/SiteContext';

import MatchMediaQuery from 'Components/MatchMediaQuery';
import AppIcons from 'Components/AppIcons';
import AppLogo from 'Components/AppIcons/Logo';

import UserInfo from './UserInfo';
import {
  HeaderWrapper,
  ToggleWrapper,
  HeaderSection,
  SearchWrapper,
  GlobalSearch,
} from './styled';

const Header = ({ history, toggleSidebar }) => {
  const { userInfo, logoutUser } = useSiteValue();

  const onClickLogo = () => {
    history.push(`/app`);
  };

  const onClickLogout = () => {
    logoutUser();
    history.push('/login');
  };

  const onClickSearch = value => {
    if (value) {
      history.push(`/app/search/${value.trim()}`);
    }
  };

  return (
    <HeaderWrapper>
      <ToggleWrapper onClick={toggleSidebar}>
        <AppIcons name="toggleMenuIcon" width="1.7em" height="1.7em" />
      </ToggleWrapper>
      <HeaderSection>
        <AppLogo onClick={onClickLogo} width="10em" />
        <MatchMediaQuery maxWidth="tablet">
          <SearchWrapper>
            <GlobalSearch
              placeholder="Search Package"
              applySearchOnEnter={onClickSearch}
            />
          </SearchWrapper>
        </MatchMediaQuery>
        <UserInfo userInfo={userInfo} onClickLogout={onClickLogout} />
      </HeaderSection>
    </HeaderWrapper>
  );
};

export default Header;
