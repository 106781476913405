import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import { EmptyElement, EmptyChildren } from './styled';

const Empty = ({
  variant,
  color,
  message,
  children,
  messageProps,
  ...restProps
}) => (
  <EmptyElement {...restProps}>
    {children && <EmptyChildren>{children}</EmptyChildren>}
    <Typography variant={variant} color={color} {...messageProps}>
      {message}
    </Typography>
  </EmptyElement>
);

Empty.defaultProps = {
  color: 'subtext',
  message: 'No Data',
  variant: 'h3',
  messageProps: {},
  center: false,
};

Empty.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  center: PropTypes.bool,
  message: PropTypes.string,
};

export default Empty;
