import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'Components/UI';
import { AppLoadingBar } from 'Components/AppLoader';
import SiteLayout from 'App/Layout';
import TabComponentsMap from 'Pages';
import App from './app';
import GlobalStyle from './theme/GlobalStyle';
import theme from './theme';
import store from './store';

const RenderApp = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <BrowserRouter basename="/#">
            <SiteLayout>
              <React.Suspense fallback={<AppLoadingBar />}>
                <Switch>
                  <Route path="/login" component={TabComponentsMap.LOGIN} />
                  <Route path="/app/return-cosignment-challan" component={TabComponentsMap.RETURN_CONSIGNMENT_CHALLAN} />
                  <Route path="/app" component={App} />
                  <Redirect from="/" to="/login" />
                </Switch>
              </React.Suspense>
            </SiteLayout>
          </BrowserRouter>
          <ToastContainer position={ToastContainer.POSITION.TOP_RIGHT} />
          <ToastContainer position={ToastContainer.POSITION.TOP_CENTER} />
        </>
    </ThemeProvider>
  </Provider>
);

ReactDOM.render(<RenderApp />, document.getElementById('root'));
