import React from 'react';
import { Arrow } from 'Components/Icons';

import {
  Table,
  TableRow,
  TableHeader,
  TableCell,
  TableBody,
  TableFooter,
} from './styled';

const SORT_DIRECTIONS = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

const TableSortCell = ({ children, sortBy, cellKey, ...props }) => {
  const direction =
    sortBy.key === cellKey &&
    (sortBy.order === SORT_DIRECTIONS.ASCENDING ? 'down' : 'up');
  return (
    <TableCell actionable {...props}>
      {children}
      <Arrow color="default" className="sortIcon" direction={direction} />
    </TableCell>
  );
};

Table.Header = TableHeader;
Table.Footer = TableFooter;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Cell = TableCell;
Table.SortCell = TableSortCell;
Table.SORT_DIRECTIONS = SORT_DIRECTIONS;

export default Table;
