import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import { Wrapper } from './styled';

const Alert = ({ message, color, variant, ...props }) => (
  <Wrapper data-testid={`alert-${color}`} color={color} {...props}>
    <Typography color={color} variant={variant}>
      {message}
    </Typography>
  </Wrapper>
);

Alert.defaultProps = {
  variant: 'subText',
  color: 'primary',
};

Alert.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  message: PropTypes.string.isRequired,
};

export default Alert;
