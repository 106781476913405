import React from 'react';

import { Typography } from '../../index';

const Label = ({ children, ...restProps }) => (
  <Typography {...restProps} element="label" variant="label">
    {children}
  </Typography>
);

export default Label;
