import React, { useState, useEffect } from 'react';

import { Input } from 'Components/UI';
import { Search, Cross } from 'Components/Icons';

const ENTER_KEY = 13;

const SearchInput = ({
  isClear = false,
  onChange,
  clearSearch,
  applySearchOnEnter,
  ...props
}) => {
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    if (isClear) {
      setSearchText('');
    }
  }, [isClear]);

  const onClickClearSearch = () => {
    setSearchText('');
    if (onChange) {
      onChange('');
    }

    if (clearSearch) {
      clearSearch('');
    }
  };

  const onChangeSearchText = ({ target, keyCode }) => {
    setSearchText(target.value);

    if (onChange) {
      onChange(target.value);
    }

    if (keyCode === ENTER_KEY && applySearchOnEnter) {
      applySearchOnEnter(target.value);
    }
  };

  return (
    <Input
      suffix={
        searchText ? (
          <Cross cursor="pointer" onClick={onClickClearSearch} />
        ) : (
          <Search width="1.2em" />
        )
      }
      value={searchText}
      onChange={onChangeSearchText}
      onKeyDown={onChangeSearchText}
      {...props}
    />
  );
};

export default SearchInput;
