import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Input } from './styled';

const SwitchBase = ({
  color,
  unCheckColor,
  disabled,
  wrapperProps,
  children,
  checked,
  childrenProps,
  size,
  ...restProps
}) => (
  <Wrapper disabled={disabled} {...wrapperProps}>
    {children({ checked, color, unCheckColor, disabled, size, ...childrenProps })}
    <Input checked={checked} disabled={disabled} {...restProps} />
  </Wrapper>
);

SwitchBase.defaultProps = {
  color: 'primary',
  unCheckColor: 'default',
  disabled: false,
  checked: false,
  wrapperProps: {},
  childrenProps: {},
};

SwitchBase.propTypes = {
  color: PropTypes.string,
  unCheckColor: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};

export default SwitchBase;
