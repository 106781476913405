import React from 'react';
import { Icon } from 'Components/Icons';

const InterstatePackage = props => (
  <Icon viewBox="0 0 23 22" {...props}>
        <path d="M9.99998 19.1667C8.73192 19.1667 7.54026 18.9261 6.42498 18.4448C5.3097 17.9636 4.33956 17.3105 3.51456 16.4855C2.68956 15.6605 2.03644 14.6903 1.55519 13.575C1.07394 12.4598 0.833313 11.2681 0.833313 10C0.833313 8.73199 1.07394 7.54032 1.55519 6.42504C2.03644 5.30976 2.68956 4.33962 3.51456 3.51462C4.33956 2.68962 5.3097 2.0365 6.42498 1.55525C7.54026 1.074 8.73192 0.833374 9.99998 0.833374C11.268 0.833374 12.4597 1.074 13.575 1.55525C14.6903 2.0365 15.6604 2.68962 16.4854 3.51462C17.3104 4.33962 17.9635 5.30976 18.4448 6.42504C18.926 7.54032 19.1666 8.73199 19.1666 10C19.1666 11.2681 18.926 12.4598 18.4448 13.575C17.9635 14.6903 17.3104 15.6605 16.4854 16.4855C15.6604 17.3105 14.6903 17.9636 13.575 18.4448C12.4597 18.9261 11.268 19.1667 9.99998 19.1667ZM9.01456 17.7688V15.8896C8.47984 15.8896 8.02915 15.691 7.66248 15.2938C7.29581 14.8966 7.11248 14.4306 7.11248 13.8959V12.8875L2.41456 8.18962C2.33817 8.49518 2.2847 8.79692 2.25415 9.09483C2.22359 9.39275 2.20831 9.69449 2.20831 10C2.20831 11.9862 2.8538 13.7202 4.14477 15.2021C5.43574 16.6841 7.05901 17.5396 9.01456 17.7688ZM15.7521 15.2938C16.4243 14.5605 16.9323 13.7393 17.276 12.8302C17.6198 11.9212 17.7916 10.9778 17.7916 10C17.7916 8.3806 17.3486 6.91011 16.4625 5.58858C15.5764 4.26705 14.3923 3.30074 12.9104 2.68962V3.10212C12.9104 3.63685 12.7271 4.10282 12.3604 4.50004C11.9937 4.89726 11.543 5.09587 11.0083 5.09587H9.01456V7.08962C9.01456 7.34935 8.91144 7.56323 8.70519 7.73129C8.49894 7.89935 8.26595 7.98337 8.00623 7.98337H6.10415V10H12.0166C12.2764 10 12.4903 10.0993 12.6583 10.298C12.8264 10.4966 12.9104 10.7257 12.9104 10.9855V13.8959H13.8958C14.3389 13.8959 14.7285 14.0257 15.0646 14.2855C15.4007 14.5452 15.6298 14.8813 15.7521 15.2938Z"/>
  </Icon>
);

export default InterstatePackage;
