import React from 'react';
import { Icon } from 'Components/Icons';

const Reports = props => (
  <Icon viewBox="0 0 24 24" {...props} fill="none">
    <path
      d="M5 10.2a1 1 0 011-1h1a1 1 0 011 1V18a1 1 0 01-1 1H6a1 1 0 01-1-1v-7.8zM16.2 14a1 1 0 011-1h.8a1 1 0 011 1v4a1 1 0 01-1 1h-.8a1 1 0 01-1-1v-4zm-5.6-8a1 1 0 011-1h.8a1 1 0 011 1v12a1 1 0 01-1 1h-.8a1 1 0 01-1-1V6z"
      fill="currentColor"
    />
  </Icon>
);

export default Reports;
