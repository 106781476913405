import React from 'react';
import { Icon } from 'Components/Icons';

const ReverseLogistics = props => (
  <Icon viewBox="0 0 24 24" {...props} fill="none">
    <path
      opacity=".3"
      d="M10.343 14a.343.343 0 00-.343.343c0 .305-.37.458-.585.242l-2.231-2.231a.5.5 0 010-.708l2.231-2.231a.343.343 0 01.585.242c0 .19.153.343.343.343H17.5a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-7.157z"
      fill="currentColor"
    />
    <path
      d="M4.354 12.354a.5.5 0 010-.708l6.792-6.792a.5.5 0 01.854.353V7.5a.5.5 0 00.5.5h7a.5.5 0 01.5.5v7a.5.5 0 01-.5.5h-7a.5.5 0 00-.5.5v2.293a.5.5 0 01-.854.353l-6.792-6.792zM17.5 14a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-7.157A.343.343 0 0110 9.657a.343.343 0 00-.585-.242l-2.231 2.231a.5.5 0 000 .708l2.231 2.231a.343.343 0 00.585-.242c0-.19.153-.343.343-.343H17.5z"
      fill="currentColor"
    />
  </Icon>
);

export default ReverseLogistics;
